import { createAction, createReducer } from "@reduxjs/toolkit"
import { LOADING_INITIAL_STATE } from "modules/loader-watchers/helpers/loading"

// ACTIONS
export const setMenuItemActive = createAction("proWizardMenu/setMenuItemActive")
export const setValidation = createAction("proWizardMenu/setValidation")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  activeMenu: null,
  validation: false
}

const proWizardMenuReducer = createReducer(initialState, {
  [setMenuItemActive.type]: (state, action) => {
    state.activeMenu = action.payload
  },
  [setValidation.type]: (state, action) => {
    state.validation = action.payload
  }
})

export default proWizardMenuReducer
