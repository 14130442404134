import React from "react"
import { Modal, Button } from "antd"
import { Title, Text } from "ui/Typography"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"
import styles from "./ConfirmModal.module.css"

export const ConfirmModal = ({
  open,
  title,
  info,
  onCancel,
  onOk,
  onExit,
  exitLabel = "Leave without saving",
  cancelLabel = "Cancel",
  okLabel = "Save",
  hideCancelButton
}) => {
  return (
    <Modal
      className={styles.confirmModal}
      title={
        <div className="justify-center">
          <div className={styles.circleWrapper}>
            <Icon name="confirmDisclaimer" color={colors.error} />
          </div>
        </div>
      }
      open={open}
      footer={[
        onExit && (
          <Button key="exit" color="danger" variant="outlined" size="large" className="px-xl" onClick={onExit}>
            {exitLabel}
          </Button>
        ),
        !hideCancelButton && (
          <Button key="cancel" color="primary" variant="outlined" size="large" className="px-xl" onClick={onCancel}>
            {cancelLabel}
          </Button>
        ),
        <Button key="save" type="primary" size="large" className="px-xl" onClick={onOk}>
          {okLabel}
        </Button>
      ]}
      onCancel={onCancel}
      width={430}
    >
      <div className="mb-xl">
        {(info || title) && (
          <>
            {title && <Title level={3}>{title}</Title>}
            {info && <Text type="body">{info}</Text>}
          </>
        )}
      </div>
    </Modal>
  )
}
