import React, { useMemo } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { MediaPreview } from "ui/MediaPreview"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"
import styles from "./MediaGrid.module.css"

const SortableMediaItem = ({ id, media, onImageClick, withActions, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const mediaPreview = useMemo(() => {
    return <MediaPreview key={media?.id} media={media} onClick={() => onImageClick?.(media)} lazyLoad={false} />
  }, [media, onImageClick])

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="relative w-full h-full">
      {mediaPreview}
      {withActions && (
        <div
          className={styles.actionWrapper}
          onClick={(e) => {
            e.stopPropagation()
            onDelete?.(media)
          }}
          onPointerDown={(e) => {
            e.stopPropagation()
          }}
        >
          <Icon type="feather" name="X" width={16} height={16} strokeWidth={2} color={colors.textSecondary} />
        </div>
      )}
    </div>
  )
}

export default SortableMediaItem
