import React, { useState } from "react"
import { Card } from "ui/Card"
import { Form, Input, Radio as AntdRadio, InputNumber, Row, Col } from "antd"
import { useTranslation } from "react-i18next"
import { Radio } from "ui/Radio"
import { Text } from "ui/Typography"

const { TextArea } = Input

export const GuestDetails = () => {
  const [t] = useTranslation()
  const [requiresLicense, setRequiresLicense] = useState(false)

  return (
    <Card title={t("experiences.details.guest_details")} className="mb-3xl">
      <Text type="subtitle" size="lg" fw="500" className="block mb-xxl">
        {t("experiences.details.guest_details")}
      </Text>
      <Row className="mb-l" gutter={16}>
        <Col span={12}>
          <Form.Item
            name="min_guests"
            label="Minimum"
            rules={[{ required: true, message: t("experiences.details.errors.minimum_guests") }]}
          >
            <InputNumber size="large" placeholder="1" min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="max_guests"
            label="Maximum"
            rules={[{ required: true, message: t("experiences.details.errors.maximum_guests") }]}
          >
            <InputNumber size="large" placeholder="10" min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="licenses_required"
        label={t("experiences.details.labels.licenses_required_info")}
        initialValue="no"
        rules={[{ required: true }]}
      >
        <AntdRadio.Group onChange={(e) => setRequiresLicense(e.target.value === "yes")}>
          <Radio value="yes">{t("global.yes")}</Radio>
          <Radio value="no">{t("global.no")}</Radio>
        </AntdRadio.Group>
      </Form.Item>

      {requiresLicense && (
        <Form.Item
          name="licenses_list"
          label={t("global.licenses")}
          rules={[{ required: true, message: t("experiences.details.errors.licenses_required") }]}
        >
          <TextArea placeholder={t("experiences.details.placeholders.licenses")} rows={4} />
        </Form.Item>
      )}
    </Card>
  )
}
