import { ProgressBar } from "ui/ProgressBar"
import styles from "./ProNewListingLayout.module.css"
import { PageName } from "ui/PageName"
import { Tabs } from "antd"
import { proNewListingMenuList } from "./proNewListingMenuList"
import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { PageTitle } from "./PageTitle"

export const ProNewListingLayout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id: tripId } = useParams()
  const tabId = pathname.split("/").filter(Boolean).pop()

  // get attributes of current tab
  const { id, percent, title, subTitle } = useMemo(() => {
    return proNewListingMenuList.find((tabItem) => tabItem.id === tabId) || proNewListingMenuList[0]
  }, [tabId])

  const onChange = (key) => {
    const nextTab = proNewListingMenuList.find((tabItem) => tabItem.id === key)
    if (nextTab?.path && tripId) {
      navigate(nextTab.path(tripId))
    }
  }

  const tabList = useMemo(() => {
    return proNewListingMenuList.map((item) => ({
      key: item.id,
      label: item.label
    }))
  }, [])

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <ProgressBar percent={percent} />
        <PageName title={t("listings.create_new")} />
        <Tabs activeKey={id} items={tabList} onChange={onChange} />
      </div>
      <div className={styles.body}>
        <PageTitle title={title} subTitle={subTitle} />
        <div className={styles.pageContent}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
