import { Form, Radio, Flex, Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import { SEASON_TYPES } from '../utils/constants'

export const SeasonTypeSelector = ({ onChange, value }) => {
  const { t } = useTranslation()
  
  return (
    <Form.Item>
      <Radio.Group
        onChange={onChange}
        defaultValue={SEASON_TYPES.ALL_YEAR}
        value={value}
      >
        <Radio value={SEASON_TYPES.ALL_YEAR}>
          {t("listings.tabs.scheduling.sections.seasons.all_year")}
        </Radio>
        <Radio value={SEASON_TYPES.SEASONAL}>
          <Flex align="center" gap="small">
            {t("listings.tabs.scheduling.sections.seasons.seasonal")}
            <Tooltip title={t("listings.tabs.scheduling.sections.seasons.seasonal_tooltip")}>
              <InfoCircleOutlined style={{ color: '#8C8C8C' }} />
            </Tooltip>
          </Flex>
        </Radio>
      </Radio.Group>
    </Form.Item>
  )
}

SeasonTypeSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(SEASON_TYPES)).isRequired
} 
