import React from "react"
import styles from "./MenuItem.module.css"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { getProWizardErrorsObject, useIsCompleted } from "./useCompletion"
import { CircledIcon } from "ui/CircledIcon"
import { receiveErrors } from "modules/errors/reducer"

export const MenuItem = ({ item }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { activeMenu, validation } = useSelector((state) => state.proWizardMenu)
  const user = useSelector((store) => store.user)
  const { name, id, progressWeight } = item
  const isActive = activeMenu === id
  const isCompleted = useIsCompleted(id)
  const onClickHandler = () => {
    if (item.path) {
      navigate(item.path)
      if (validation) {
        const errorsObject = getProWizardErrorsObject(user)
        dispatch(
          receiveErrors({
            data: { message: errorsObject },
            showToast: false
          })
        )
      }
    }
  }

  return (
    <div
      className={classNames(styles.menuGroupItem, isActive && styles.menuGroupItemActive, isCompleted && styles.menuGroupItemCompleted)}
      onClick={onClickHandler}
    >
      {name}
      {progressWeight > 0 &&
        (isCompleted ? (
          <CircledIcon name="Check" colorType="success" />
        ) : (
          validation && <CircledIcon name="AlertCircle" colorType="error" />
        ))}
    </div>
  )
}
