import { useSelector } from "react-redux"
import { proWizardMenuKeys, proWizardMenuList } from "./proWizardMenuList"
import i18n from "i18n"

const menuItemsFlat = proWizardMenuList.flatMap((menuItem) => menuItem.items)

export const getProWizardErrorsList = (user) => {
  const { avatar, guide } = user
  const {
    display_name,
    company_name,
    description,
    country,
    state,
    city,
    zip_code,
    street_address,
    photos,
    videos,
    documents,
    stripe_account_data
  } = guide
  const isStripeConnected = !!Object.keys(stripe_account_data).length

  const errors = []

  // 1. Profile Information Errors
  if (!avatar) {
    errors.push({
      fieldName: "avatarPhoto",
      category: proWizardMenuKeys.profileInformation,
      errorMessage: i18n.t("form_validation.profile_photo_missing")
    })
  }

  if (!display_name) {
    errors.push({
      fieldName: "display_name",
      category: proWizardMenuKeys.profileInformation,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!company_name) {
    errors.push({
      fieldName: "company_name",
      category: proWizardMenuKeys.profileInformation,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!description) {
    errors.push({
      fieldName: "description",
      category: proWizardMenuKeys.profileInformation,
      errorMessage: i18n.t("form_validation.description_missing")
    })
  }

  // 2. Profile Images Errors
  if ((photos?.length || 0) + (videos?.length || 0) < 2) {
    errors.push({
      fieldName: "profile_images",
      category: proWizardMenuKeys.profileImages,
      errorMessage: i18n.t("form_validation.images_missing")
    })
  }

  // 3. Address Errors
  if (!country) {
    errors.push({
      fieldName: "country",
      category: proWizardMenuKeys.addresses,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!state) {
    errors.push({
      fieldName: "state",
      category: proWizardMenuKeys.addresses,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!city) {
    errors.push({
      fieldName: "city",
      category: proWizardMenuKeys.addresses,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!zip_code) {
    errors.push({
      fieldName: "zip_code",
      category: proWizardMenuKeys.addresses,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!street_address) {
    errors.push({
      fieldName: "street_address",
      category: proWizardMenuKeys.addresses,
      errorMessage: i18n.t("form_validation.cannot_be_blank")
    })
  }

  if (!country || !state || !city || !zip_code || !street_address) {
    errors.push({
      fieldName: "addresses",
      category: proWizardMenuKeys.addresses,
      errorMessage: i18n.t("form_validation.addresses_missing")
    })
  }

  // 4. Licenses Errors
  if (!documents.some((document) => document.file_type === "driver_license")) {
    errors.push({
      fieldName: "documents",
      category: proWizardMenuKeys.licenses,
      errorMessage: i18n.t("form_validation.documents_missing")
    })
  }

  // 5. Stripe Errors
  if (!isStripeConnected) {
    errors.push({
      fieldName: "stripe",
      category: proWizardMenuKeys.stripe,
      errorMessage: i18n.t("form_validation.stripe_missing")
    })
  }

  return errors
}

export const getProWizardErrorsObject = (user) => {
  const errors = getProWizardErrorsList(user)
  const errorMessage = {}
  errors.forEach((error) => {
    errorMessage[error.fieldName] = [error.errorMessage]
  })
  return errorMessage
}

const useGetCompletionList = () => {
  const user = useSelector((store) => store.user)
  const errors = getProWizardErrorsList(user)
  const completionList = {}
  for (const [key, value] of Object.entries(proWizardMenuKeys)) {
    completionList[value] = errors.filter((error) => error.category === value).length === 0
  }
  return completionList
}

export const useIsCompleted = (menuId) => {
  const completionList = useGetCompletionList()
  return completionList[menuId]
}

export const useProgressValue = () => {
  let progressValue = 0
  const completionList = useGetCompletionList()
  menuItemsFlat.forEach((item) => {
    if (completionList[item.id]) {
      progressValue += item.progressWeight
    }
  })

  return progressValue
}
