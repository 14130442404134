import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "antd"
import { DndContext, closestCenter } from "@dnd-kit/core"
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable"
import SortableMediaItem from "./SortableMediaItem"
import styles from "./MediaGrid.module.css"

const MediaGrid = ({ mediaItems = [], onImageClick, withActions = false, onDelete, onReorder }) => {
  const [items, setItems] = useState(mediaItems)
  const handleDragEnd = (event) => {
    const { active, over } = event
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)
        const newItems = arrayMove(items, oldIndex, newIndex)

        if (onReorder) {
          onReorder(newItems)
        }

        return newItems
      })
    }
  }

  if (items.length === 0) return null

  const gutter = [12, 8]

  if (items.length < 5) {
    const spanLength = items.length > 1 ? 24 / items.length : 12
    return (
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Row gutter={gutter}>
            {items.map((media) => (
              <Col className={styles.singleImage} key={media.id} span={spanLength}>
                <SortableMediaItem
                  id={media.id}
                  key={media.id}
                  media={media}
                  onImageClick={onImageClick}
                  withActions={withActions}
                  onDelete={onDelete}
                />
              </Col>
            ))}
          </Row>
        </SortableContext>
      </DndContext>
    )
  }

  const renderGrid = (startIndex) => (
    <>
      <Col className={styles.heroImage} span={12}>
        <SortableMediaItem
          id={items[startIndex].id}
          media={items[startIndex]}
          index={startIndex}
          onImageClick={onImageClick}
          withActions={withActions}
          onDelete={onDelete}
        />
      </Col>
      {items[startIndex + 1] && (
        <Col span={12}>
          <Row gutter={gutter}>
            {Array.from({ length: 4 }).map(
              (_, i) =>
                items[startIndex + i + 1] && (
                  <Col key={items[startIndex + i + 1].id} span={12} className={styles.thumbnailImage}>
                    <SortableMediaItem
                      id={items[startIndex + i + 1].id}
                      media={items[startIndex + i + 1]}
                      index={startIndex + i + 1}
                      onImageClick={onImageClick}
                      withActions={withActions}
                      onDelete={onDelete}
                    />
                  </Col>
                )
            )}
          </Row>
        </Col>
      )}
      {items[startIndex + 5] && (
        <Col className={styles.heroImage} span={12}>
          <SortableMediaItem
            id={items[startIndex + 5].id}
            media={items[startIndex + 5]}
            index={startIndex + 5}
            onImageClick={onImageClick}
            withActions={withActions}
            onDelete={onDelete}
          />
        </Col>
      )}
      {items[startIndex + 6] && (
        <Col className={styles.heroImage} span={12}>
          <SortableMediaItem
            id={items[startIndex + 6].id}
            media={items[startIndex + 6]}
            index={startIndex + 6}
            onImageClick={onImageClick}
            withActions={withActions}
            onDelete={onDelete}
          />
        </Col>
      )}
      {Array.from({ length: 4 }).map(
        (_, i) =>
          items[startIndex + 7 + i] && (
            <Col key={i} span={6} className={styles.thumbnailImage}>
              <SortableMediaItem
                id={items[startIndex + 7 + i].id}
                media={items[startIndex + 7 + i]}
                index={startIndex + 7 + i}
                onImageClick={onImageClick}
                withActions={withActions}
                onDelete={onDelete}
              />
            </Col>
          )
      )}
    </>
  )

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Row gutter={gutter}>
          {Array.from({ length: Math.ceil(items.length / 11) }).map((_, i) => (
            <React.Fragment key={i}>{renderGrid(i * 11)}</React.Fragment>
          ))}
        </Row>
      </SortableContext>
    </DndContext>
  )
}

MediaGrid.propTypes = {
  mediaItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      placeholder_url: PropTypes.string
    })
  ).isRequired,
  onImageClick: PropTypes.func,
  withActions: PropTypes.bool,
  onDelete: PropTypes.func,
  onReorder: PropTypes.func // New prop for handling reordering
}

export { MediaGrid }
