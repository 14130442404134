import React, { useState } from "react"
import { Button, Col, Form, Row } from "antd"
import useFormErrors from "hooks/useAntdFormErrors"
import { serialize } from "object-to-formdata"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { guideSelector, updateGuide, updateUser } from "store/user"
import { CustomForm } from "ui/Form"
import { CustomInput, TextArea } from "ui/Input"
import { AddAvatar } from "ui/PersonalInfo/ProfileInformation/AddAvatar"
import { compressFile } from "helpers/compress"
import { useDevice } from "hooks/useDevice"
import { useNavigate } from "react-router-dom"
import { notification } from "ui/Notification"
import { ChangeEmailPhoneForm } from "../LoginAndSecurity/ChangeEmailPhoneForm"
import { CountrySelector } from "ui/Input/CountrySelector"

export const ProfileInformation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isFieldsTouched, setIsFieldsTouched] = useState(false)
  const {
    id,
    display_name,
    company_name,
    first_name,
    last_name,
    email,
    website,
    description,
    country,
    street_address,
    city,
    state,
    zip_code
  } = useSelector(guideSelector)
  const { avatar } = useSelector((store) => store.user)
  const { loading } = useSelector((state) => state.auth)
  const { isDesktop } = useDevice()
  const navigate = useNavigate()

  const requiredErrorMessage = t("form_validation.cannot_be_blank")

  const onFinish = (formData) => {
    const serializedFormData = serialize({ guide: formData }, { indices: true })
    dispatch(updateGuide(id, serializedFormData))
      .then(() => {
        setIsFieldsTouched(false)
        notification.success({
          message: t("user.guide_success_update")
        })
      })
      .catch(() => {
        notification.error({
          message: t("user.guide_update_error")
        })
      })
  }

  const handleFieldsChange = () => {
    setIsFieldsTouched(true)
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }

  const handleCancel = () => {
    handleNavigateBack()
  }

  const handleImageChange = async (e) => {
    const avatar = await compressFile(e.target.files[0])
    const formData = serialize({ avatar })
    if (avatar) dispatch(updateUser(formData))
  }

  useFormErrors(form)

  return (
    <>
      <CustomForm layout="vertical">
        <Form.Item name="avatarPhoto" label={t("guide.profile_image")} required={true} className="mb-4xl">
          <AddAvatar avatar={avatar} handleImageChange={handleImageChange} />
        </Form.Item>
      </CustomForm>
      <CustomForm
        form={form}
        name="settings-profile-information"
        layout="vertical"
        initialValues={{
          display_name,
          company_name,
          email,
          website,
          description,
          country,
          street_address,
          city,
          state,
          zip_code,
          user_attributes: {
            first_name,
            last_name
          }
        }}
        onFinish={onFinish}
      >
        <Row gutter={12}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={t("guide.display_name")}
              name="display_name"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput placeholder={t("guide.display_name")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item label={t("guide.company_name")} name="company_name">
              <CustomInput placeholder={t("guide.company_name")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={t("sign_up.first_name")}
              name={["user_attributes", "first_name"]}
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput placeholder={t("sign_up.first_name")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={t("sign_up.last_name")}
              name={["user_attributes", "last_name"]}
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput placeholder={t("sign_up.last_name")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={t("user.email")}
              name="email"
              required={true}
              rules={[
                { required: true, message: requiredErrorMessage },
                { type: "email", message: t("form_validation.invalid_email") }
              ]}
            >
              <CustomInput placeholder={t("user.email")} onChange={handleFieldsChange} disabled />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <ChangeEmailPhoneForm inputType="phone_number" />
          </Col>
        </Row>

        <Form.Item label={t("guide.website")} name="website">
          <CustomInput placeholder="https://" onChange={handleFieldsChange} />
        </Form.Item>
        <Form.Item
          label={t("guide.description")}
          name="description"
          required={true}
          rules={[{ required: true, message: requiredErrorMessage }]}
        >
          <TextArea placeholder={t("guide.description_placeholder")} style={{ height: 147 }} onChange={handleFieldsChange} />
        </Form.Item>

        <Form.Item
          label={t("pro.addresses.street_address")}
          name="street_address"
          required={true}
          rules={[{ required: true, message: requiredErrorMessage }]}
        >
          <CustomInput placeholder={t("pro.addresses.street_address")} onChange={handleFieldsChange} />
        </Form.Item>

        <Row gutter={16}>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={t("pro.addresses.city")}
              name="city"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput placeholder={t("pro.addresses.city")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={t("pro.addresses.state")}
              name="state"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput placeholder={t("pro.addresses.state")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={t("pro.addresses.zip")}
              name="zip_code"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput placeholder={t("pro.addresses.zip")} onChange={handleFieldsChange} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("pro.addresses.country")}
          name="country"
          required={true}
          rules={[{ required: true, message: requiredErrorMessage }]}
        >
          <CountrySelector onChange={handleFieldsChange} />
        </Form.Item>

        <div className="flex gap-s mt-4xl mb-4xl">
          <Button variant="outlined" color="primary" size="large" style={{ width: isDesktop ? "auto" : "50%" }} onClick={handleCancel}>
            {t("global.cancel")}
          </Button>
          <Button
            className="mb-xs"
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: isDesktop ? "auto" : "50%" }}
            disabled={loading || !isFieldsTouched}
          >
            {t("settings.update")}
          </Button>
        </div>
      </CustomForm>
    </>
  )
}
