import React from "react"
import styles from "./SubHeader.module.css"
import { useTranslation } from "react-i18next"
import { Title } from "ui/Typography"
import { ProgressBar } from "ui/ProgressBar"
import { useProgressValue } from "./useCompletion"
import { useDevice } from "hooks/useDevice"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import { Icon } from "ui/Icon"

export const SubHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const progressValue = useProgressValue()
  const { isMobile, isTablet } = useDevice()
  let titleLevel = 2
  let progressBarHeight = 38

  if (isTablet) {
    progressBarHeight = 32
  }

  if (isMobile) {
    titleLevel = 4
    progressBarHeight = 16
  }

  const handleCloseButtonClick = () => {
    navigate(routes.guideDashboardPath())
  }

  return (
    <div className={styles.subHeader}>
      <div className={styles.titleWrapper}>
        <Title className="mb-0" level={titleLevel}>
          {t("pro.wizard.title")}
        </Title>
      </div>
      <div className={styles.progressWrapper}>
        <ProgressBar percent={progressValue} height={progressBarHeight} />
      </div>
      <div className={styles.closeButtonWrapper}>
        <Button color="primary" variant="outlined" size="large" className="pr-xl pl-xl" onClick={handleCloseButtonClick}>
          {t("global.close")}
        </Button>
      </div>
      <div onClick={handleCloseButtonClick} className={styles.closeIconWrapper}>
        <Icon width={24} height={24} type="feather" name="X" color="currentColor" />
      </div>
    </div>
  )
}
