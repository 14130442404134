import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import { Text } from "ui/Typography"
import { PlusOutlined } from "@ant-design/icons"
import experiencesSVG from "assets/imagesV2/experiences.svg"
import styles from "./ExperiencesHome.module.css"

const EmptyState = ({ onAddNewExperience }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.emptyState}>
      <div className={styles.imgWrapper}>
        <img src={experiencesSVG} alt="" height="100px" width="auto" />
      </div>
      <Text className="mb-s" variant="sub" size="lg">
        {t("experiences.empty_state.info")}
      </Text>
      <Text className="mb-xxl" variant="body">
        {t("experiences.empty_state.subtitle")}
      </Text>
      <Button type="primary" size="large" icon={<PlusOutlined />} onClick={onAddNewExperience}>
        {t("experiences.empty_state.add")}
      </Button>
    </div>
  )
}

export const TabContent = ({ items, onAddNewExperience }) => {
  if (items.length === 0) {
    return <EmptyState onAddNewExperience={onAddNewExperience} />
  }

  return (
    <div>
      {" "}
      <h1>Render items here</h1>
    </div>
  )
}
