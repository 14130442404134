export const MAX_MEDIA_SIZE = {
  image: 10,
  video: 25
}

const imageFormats = ["image/png", "image/jpg", "image/jpeg", "image/gif"]
const videoFormats = ["video/mov", "video/quicktime", "video/mp4", "video/m4v", "video/webm"]

export const ACCEPTED_MEDIA_TYPES = {
  image: imageFormats,
  video: videoFormats,
  all: [...imageFormats, ...videoFormats]
}
