import { Form, DatePicker, Button, Flex, Space } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import { Text } from "ui/Typography"
import { DATE_FORMAT, DATE_PICKER_PROPS } from '../utils/constants'

const ICON_WIDTH = 32

export const DateRangeBlock = ({ 
  blockIndex, 
  onDelete, 
  isFirstBlock, 
  isSeasonalActive, 
  initialStartDate, 
  initialEndDate 
}) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()

  const handleStartDateChange = () => {
    const startDate = form.getFieldValue(`seasonStart_${blockIndex}`)
    const endDate = form.getFieldValue(`seasonEnd_${blockIndex}`)
    if (endDate && startDate && endDate.isBefore(startDate)) {
      form.setFieldValue(`seasonEnd_${blockIndex}`, undefined)
    }
  }

  const isEndDateDisabled = (current) => {
    const startDate = form.getFieldValue(`seasonStart_${blockIndex}`)
    return current && startDate && current.isBefore(startDate)
  }

  return (
    <div className="mb-l">
      <Space direction="vertical" className="w-full">
        <Flex className="w-full">
          <div className="flex-1">
            <Text fw="600">
              {t("global.date_range.from")}
              {isSeasonalActive && <span className="text-red-500 ml-1">*</span>}
            </Text>
          </div>
          <div className="flex-1">
            <Text fw="600">
              {t("global.date_range.to")}
              {isSeasonalActive && <span className="text-red-500 ml-1">*</span>}
            </Text>
          </div>
          <div style={{ width: ICON_WIDTH }} />
        </Flex>
        
        <Flex gap="middle" align="center">
          <Form.Item
            name={`seasonStart_${blockIndex}`}
            rules={[{
              required: isSeasonalActive,
              message: t("global.date_range.start_validation")
            }]}
            initialValue={initialStartDate}
            className="mb-0 flex-1"
          >
            <DatePicker
              format={DATE_FORMAT}
              placeholder={t("global.date_range.placeholder")}
              style={{ width: '100%' }}
              inputProps={DATE_PICKER_PROPS}
              onChange={handleStartDateChange}
            />
          </Form.Item>

          <Form.Item
            name={`seasonEnd_${blockIndex}`}
            rules={[{
              required: isSeasonalActive,
              message: t("global.date_range.end_validation")
            }]}
            initialValue={initialEndDate}
            className="mb-0 flex-1"
          >
            <DatePicker
              format={DATE_FORMAT}
              placeholder={t("global.date_range.placeholder")}
              style={{ width: '100%' }}
              inputProps={DATE_PICKER_PROPS}
              disabledDate={isEndDateDisabled}
            />
          </Form.Item>

          <div style={{ width: ICON_WIDTH, display: 'flex', justifyContent: 'center' }}>
            {blockIndex !== 0 && (
              <Button
                type="default"
                icon={<DeleteOutlined />}
                onClick={() => onDelete(blockIndex)}
                shape="circle"
                className="flex items-center justify-center"
              />
            )}
          </div>
        </Flex>
      </Space>
    </div>
  )
}

DateRangeBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  isFirstBlock: PropTypes.bool.isRequired,
  isSeasonalActive: PropTypes.bool.isRequired,
  initialStartDate: PropTypes.object,
  initialEndDate: PropTypes.object
}
