import React, { useEffect, useMemo } from "react"
import styles from "./Sidebar.module.css"
import { MenuGroup } from "./MenuGroup"
import { Flex } from "antd"
import { spacings } from "themes/variables"
import { proWizardMenuList } from "./proWizardMenuList"
import { useDispatch, useSelector } from "react-redux"
import { setValidation } from "store/pro-wizard-menu"
import { Text } from "ui/Typography"
import { useTranslation } from "react-i18next"
import { getProWizardErrorsList } from "./useCompletion"

export const Sidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { validation } = useSelector((state) => state.proWizardMenu)
  const user = useSelector((store) => store.user)
  const errors = useMemo(() => getProWizardErrorsList(user), [user])

  useEffect(() => {
    return () => dispatch(setValidation(false))
  }, [dispatch])

  return (
    <div className={styles.sidebar} id="proWizardSidebar">
      <Flex vertical gap={spacings.XL} className="mb-4xl">
        {proWizardMenuList.map((menuItem, index) => (
          <MenuGroup key={menuItem.id} index={index} menuItem={menuItem} />
        ))}
      </Flex>
      <Text type="danger" className="mb-m">
        {validation && errors.length > 0 && t("form_validation.complete_all")}
      </Text>
    </div>
  )
}
