import { ListingActionButtons } from "layoutsV2/pro/ProNewListingLayout/ListingActionButtons"

export const ListingResources = () => {
  return (
    <>
      <div>"Listing Resources Page"</div>
      <ListingActionButtons />
    </>
  )
}
