import React, { useState } from "react"
import { Card } from "ui/Card"
import { Form, Input, Button } from "antd"
import { AddCategoryModal } from "../AddCategoryModal"
import { useTranslation } from "react-i18next"
import { MultipleSelect } from "ui/MultipleSelect"
import { CATEGORIES } from "constants/experiences"

const { TextArea } = Input

export const ExperienceInfo = ({ form }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [t] = useTranslation()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleClose = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Card title={t("experiences.details.experience_info")} className="mb-3xl">
        <Form.Item name="categories" label="Category" rules={[{ required: true, message: "Please select at least one category" }]}>
          <MultipleSelect placeholder={t("experiences.details.select_category_placeholder")} options={CATEGORIES} />
        </Form.Item>

        <div className="mb-m mt-xs">
          <span className="text-sm">
            {t("experiences.details.no_category_info")}{" "}
            <Button type="link" onClick={showModal} style={{ padding: 0 }}>
              {t("experiences.details.request_category")}
            </Button>
          </span>
        </div>

        <Form.Item name="title" label="Listing Title" rules={[{ required: true, message: t("experiences.details.listing_title_error") }]}>
          <Input placeholder={t("experiences.details.listing_title_placeholder")} />
        </Form.Item>

        <Form.Item
          name="description"
          label={t("global.description")}
          rules={[
            { required: true, message: "Please enter a description" }
            // { min: 40, message: "Description must be at least 40 characters" }
          ]}
        >
          <TextArea placeholder={t("experiences.details.description_placeholder")} rows={4} />
        </Form.Item>
      </Card>

      <AddCategoryModal isVisible={isModalVisible} onCancel={handleClose} handleClose={handleClose} />
    </>
  )
}
