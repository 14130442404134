import React, { useState } from "react"
import { Card } from "ui/Card"
import { Form, Flex } from "antd"
import { useTranslation } from "react-i18next"
import { MultipleSelect } from "ui/MultipleSelect"
import { CheckableTag } from "ui/CheckableTag"
import { TRIP_ITEMS, TRIP_LANGUAGES, SKILL_LEVELS } from "constants/experiences"

export const ExperienceDetails = ({ form }) => {
  const [t] = useTranslation()

  const [selectedLanguages, setSelectedLanguages] = useState([TRIP_LANGUAGES[0]])
  const languages = [...TRIP_LANGUAGES]

  const [selectedSkillLevels, setSelectedSkillLevels] = useState([SKILL_LEVELS[0]])
  const skillLevels = [...SKILL_LEVELS]

  // Handle language selection
  const handleLanguageChange = (language, checked) => {
    const newSelectedLanguages = checked ? [...selectedLanguages, language] : selectedLanguages.filter((l) => l !== language)

    setSelectedLanguages(newSelectedLanguages)
    form.setFieldsValue({ languages: newSelectedLanguages.map((l) => l.value) })
  }

  const handleSkillLevelChange = (level, checked) => {
    const newSelectedSkillLevels = checked ? [...selectedSkillLevels, level] : selectedSkillLevels.filter((l) => l !== level)

    setSelectedSkillLevels(newSelectedSkillLevels)
    form.setFieldsValue({ skill_levels: newSelectedSkillLevels.map((level) => level.value) })
  }

  return (
    <Card title={t("experiences.details.experience_details")} className="mb-3xl">
      <Form.Item
        name="included_items"
        label={t("experiences.details.labels.trip_items")}
        // rules={[{ required: true, message: t("experiences.details.errors.items_required") }]}
      >
        <MultipleSelect placeholder={t("experiences.details.placeholders.select_or_type")} options={TRIP_ITEMS} />
      </Form.Item>

      <Form.Item
        name="bring_items"
        label={t("experiences.details.labels.guest_items")}
        // rules={[{ required: true, message: t("experiences.details.errors.items_required") }]}
      >
        <MultipleSelect placeholder={t("experiences.details.placeholders.select_or_type")} options={TRIP_ITEMS} />
      </Form.Item>

      <Form.Item
        name="languages"
        label={t("experiences.details.labels.languages")}
        initialValue={selectedLanguages.map((l) => l.value)}
        // rules={[{ required: true, message: t("experiences.details.errors.language_required") }]}
      >
        <Flex wrap gap="small">
          {languages.map((language) => (
            <CheckableTag
              key={language.value}
              className="mr-xs mb-xxs"
              tag={language.label}
              checked={selectedLanguages.includes(language)}
              onChange={(checked) => handleLanguageChange(language, checked)}
            />
          ))}
        </Flex>
      </Form.Item>

      <Form.Item
        name="skill_levels"
        label={t("experiences.details.labels.skill_level")}
        initialValue={selectedSkillLevels}
        // rules={[{ required: true, message: t("experiences.details.errors.skill_required") }]}
      >
        <Flex wrap gap="small">
          {skillLevels.map((level) => (
            <CheckableTag
              key={level.value}
              className="mr-xs mb-xxs"
              tag={level.label}
              checked={selectedSkillLevels.includes(level)}
              onChange={(checked) => handleSkillLevelChange(level, checked)}
            />
          ))}
        </Flex>
      </Form.Item>
    </Card>
  )
}
