import { useEffect } from "react"
import { themes } from "constants/theme"

export function useFavicon(theme) {
  const updateFavicon = (currentTheme) => {
    const sportsmanIconPath = `${process.env.PUBLIC_URL}/new-favicon/favicon_men.png`
    const sportswomanIconPath = `${process.env.PUBLIC_URL}/new-favicon/favicon_women.png`

    const faviconPath = currentTheme === themes.sportswoman ? sportswomanIconPath : sportsmanIconPath

    const updateFaviconLink = (selector) => {
      const links = document.querySelectorAll(selector)
      links.forEach((link) => {
        link.href = faviconPath
      })
    }

    updateFaviconLink("link[rel='apple-touch-icon']")
    updateFaviconLink("link[rel='icon'][sizes='192x192']")
    updateFaviconLink("link[rel='icon'][sizes='32x32']")
    updateFaviconLink("link[rel='icon'][sizes='16x16']")
  }

  useEffect(() => {
    if (theme) {
      updateFavicon(theme)
    }
  }, [theme])

  return updateFavicon
}
