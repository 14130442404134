import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { resetErrors } from "modules/errors/reducer"
import { errorsShownSelector } from "modules/errors/selectors"

function RouteChangeWatcher({ children }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const hasErrors = useSelector(errorsShownSelector)
  const { validation } = useSelector((state) => state.proWizardMenu)

  useEffect(() => {
    if (hasErrors && !validation) dispatch(resetErrors())
    // console.log("Route change: ", location)
  }, [location, validation]) // eslint-disable-line

  return children
}

export default RouteChangeWatcher
