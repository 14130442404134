import { Tag } from "antd"
import classNames from "classnames"
import { Text } from "ui/Typography"
import styles from "./CheckableTag.module.css"

export const CheckableTag = ({ className, tag, checked, onChange }) => {
  return (
    <Tag.CheckableTag className={classNames(className, styles.tag)} key={tag} checked={checked} onChange={onChange}>
      <Text type="subtitle">{tag}</Text>
    </Tag.CheckableTag>
  )
}
