import React, { useState } from "react"
import { Button, Col, Form, Row } from "antd"
import { Text } from "ui/Typography"
import { serialize } from "object-to-formdata"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Play } from "react-feather"
import useFormErrors from "hooks/useAntdFormErrors"
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
import { guideSelector, updateGuide } from "store/user"
import { spacings } from "themes/variables"
import { ProfilePreview } from "ui/PersonalInfo/ProfilePreview"
import { DragAndDrop } from "./DragAndDrop"
import { MediaUpload } from "ui/MediaUpload"
import { MediaGrid } from "ui/MediaGrid/SortableMediaGrid"
import { MAX_MEDIA_SIZE } from "constants/form"
import { VideoPlayerModal } from "ui/VideoPlayerModal"
import { isMediaFileVideo } from "utils/media-upload"
import { CustomInputError } from "ui/CustomInputError"
import { receiveErrors } from "modules/errors/reducer"
import { ContentHeader } from "layoutsV2/pro/PersonalInfoLayout/ContentHeader"
import { useDevice } from "hooks/useDevice"

export const ProfileImages = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isMobile } = useDevice()
  const [form] = Form.useForm()
  const { id, display_name, company_name, website, description, ...guide } = useSelector(guideSelector)
  const [showPreview, setShowPreview] = useState(false)
  const [localMedia, setLocalMedia] = useState([...guide.photos, ...(guide?.videos || [])].sort((a, b) => a.position - b.position))
  const [localMediaAttributes, setLocalMediaAttributes] = useState([])
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState(null)
  const activePhotosLength = localMedia.filter((photo) => !photo._destroy).length
  const hasPhotos = activePhotosLength > 0

  const handleDelete = (media) => {
    if (!media.uid && media.url) {
      const updatedPhotos = localMedia.map((item) => (item.id === media.id ? { ...item, _destroy: true } : item))
      const deletedPhotoIndex = updatedPhotos.findIndex((item) => item.id === media.id)
      const updatedPhotosWithPositions = updatedPhotos.map((item, index) => {
        if (index > deletedPhotoIndex && !item._destroy) {
          return { ...item, position: item.position - 1 }
        }
        return item
      })
      setLocalMedia(updatedPhotosWithPositions)
      setLocalMediaAttributes(
        updatedPhotosWithPositions.map(({ id, file_type, preview_url, blob, _destroy, position, file }) => ({
          id,
          file,
          file_type,
          preview_url,
          blob,
          _destroy,
          position
        }))
      )
    } else {
      const mediaPosition = localMedia.find((item) => item.id === media.id)?.position
      const filteredPhotos = localMedia.filter((item) => item.id !== media.id)
      const updatedPositions = filteredPhotos.map((item) => {
        if (item.position > mediaPosition) {
          return { ...item, position: item.position - 1 }
        }
        return item
      })
      setLocalMedia(updatedPositions)
      setLocalMediaAttributes(
        updatedPositions.map(({ id, file_type, preview_url, blob, file, _destroy, position }) => ({
          id,
          file_type,
          preview_url,
          blob,
          _destroy,
          file,
          position
        }))
      )
    }
  }

  const handleReorder = (newItems) => {
    setLocalMedia(
      newItems.map(({ id }, index) => ({
        ...localMedia.find((item) => item.id === id),
        position: index
      }))
    )
    setLocalMediaAttributes(
      newItems.map(({ id, file_type, preview_url, blob, _destroy, file }, index) => ({
        id,
        file_type,
        preview_url,
        blob,
        file,
        _destroy,
        position: index
      }))
    )
  }

  const onFinish = (formData, callback) => {
    const attributes = localMediaAttributes.map(({ id, file_type, blob, _destroy, position, file }) => ({
      file_type,
      _destroy,
      position,
      file,
      ...(!file ? { id } : {}),
      ...(file_type === "video" ? { preview: blob } : {})
    }))

    if (attributes.length > 0) {
      const serializedFormData = serialize({ guide: { media_attributes: attributes } }, { indices: true })
      dispatch(updateGuide(id, serializedFormData)).then(() => {
        setLocalMediaAttributes([])
        callback()
      })
    } else {
      callback()
    }
  }

  const onFileListChange = (photos, photosAttributes) => {
    setLocalMedia((prev) => [...prev, ...photos])
    setLocalMediaAttributes((prev) => [...prev, ...photosAttributes])
    dispatch(
      receiveErrors({
        data: { message: { profile_images: [] } },
        showToast: false
      })
    )
  }

  const handlePreviewToggle = () => setShowPreview((prev) => !prev)

  const handleMediaClick = (media) => {
    if (isMediaFileVideo(media)) {
      setSelectedMedia(media)
      setVideoModalOpen(true)
    }
  }

  const handleCloseVideoModal = () => {
    setVideoModalOpen(false)
    setSelectedMedia(null)
  }

  const { errors } = useFormErrors(form)

  const filteredMedia = localMedia.filter((photo) => !photo._destroy)

  return (
    <>
      <Row>
        <Col lg={24} md={24} xs={24} span={24}>
          <Row justify="space-between align-center" align="middle" gutter={spacings.XS}>
            <ContentHeader title={t("pro.personal_info.profile_images.title")} />
            {!isMobile && (
              <Button color="primary" size="large" variant="outlined" onClick={handlePreviewToggle} icon={<Play size={16} />}>
                {t("global.preview")}
              </Button>
            )}
          </Row>
          <div className="mb-l flex-column">
            <Text variant="body">{t("pro.personal_info.profile_images.info")}</Text>
            {errors.profile_images && <CustomInputError errorKey={errors.profile_images[0]} />}
          </div>

          {hasPhotos ? (
            <>
              <MediaUpload
                className="mb-m"
                imageInfo={t("media_upload.image_size_limit", { maxSize: MAX_MEDIA_SIZE.image })}
                videoInfo={t("media_upload.video_size_limit", { maxSize: MAX_MEDIA_SIZE.video })}
                onFileListChange={onFileListChange}
                lastPosition={activePhotosLength}
              />
              <MediaGrid
                key={filteredMedia.length}
                mediaItems={filteredMedia}
                withActions
                onDelete={handleDelete}
                onReorder={handleReorder}
                lastPosition={activePhotosLength}
                onImageClick={handleMediaClick}
              />
            </>
          ) : (
            <DragAndDrop onFileListChange={onFileListChange} />
          )}
          <ContentActionButtons onFinish={onFinish} />
        </Col>
      </Row>
      <VideoPlayerModal open={videoModalOpen} handleClose={handleCloseVideoModal} selectedMedia={selectedMedia} />
      <ProfilePreview
        show={showPreview}
        tab="about"
        guide={{
          ...guide,
          videos: localMedia.filter((media) => isMediaFileVideo(media)),
          photos: localMedia.filter((media) => !isMediaFileVideo(media)),
          display_name: form.getFieldValue("display_name"),
          description: form.getFieldValue("description")
        }}
        onClose={handlePreviewToggle}
      />
    </>
  )
}
