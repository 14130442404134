import React, { useEffect, useState } from "react"
import { Flex, Layout } from "antd"
import styles from "./Index.module.css"
import { Outlet, useLocation } from "react-router-dom"
import { NavigationPath } from "../ProHomeLayout/NavigationPath"
import { PageName } from "ui/PageName"
import { settingsMenuList } from "pagesV2/pro/Settings/ProSettings"
import { routes } from "router/routes"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const ProSettingsLayout = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [settingsItem, setSettingsItem] = useState()
  const pageTitle = settingsItem?.name || t("guide.navigation.settings")
  const navigateBackPath = settingsItem ? routes.proSettingsRootPath() : routes.proHomeRootPath()

  useEffect(() => {
    const selectedSettingsItem = settingsMenuList.find((menu) => menu.path === location.pathname)
    setSettingsItem(selectedSettingsItem)
  }, [location.pathname])

  return (
    <Layout className={classNames(styles.layout, settingsItem && styles.hasMaxWidth)}>
      <Flex className={styles.header}>
        <NavigationPath subPages={settingsItem && [settingsItem.name]} />
        {settingsItem && <PageName title={pageTitle} backPath={navigateBackPath} />}
      </Flex>
      <Outlet />
    </Layout>
  )
}
