import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { routes } from "router"
import Cable from "components/Cable"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import useAuthRedirectedToken from "hooks/useAuthRedirectedTokens"

import { useDispatch, useSelector } from "react-redux"
import { cleanUser, getUser, platformConnectConfirm } from "store/user"
import { oldRoutes } from "./old-routes"
import { VERIFICATION_STATUS } from "layoutsV2/pro/PersonalInfoLayout/constants"

const PrivateRoutes = ({ accessRole = null }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useAuthRedirectedToken(platformConnectConfirm)
  useLoaderWatchers({ watchers: "user" })
  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const { id, role } = useSelector((store) => store.user)
  const from = useLocation()

  useEffect(() => {
    if (loggedIn && !id) {
      dispatch(getUser()).then(({ role, guide }) => {
        if (role === "guide_user" && guide?.verification_status === VERIFICATION_STATUS.IN_PROGRESS) {
          navigate(routes.proPersonalInfoRootPath())
        }
      })
    }
    return () => !loggedIn && dispatch(cleanUser())
  }, [loggedIn, id]) //eslint-disable-line

  if (loggedIn && role) {
    if (role !== accessRole) return <Navigate to={oldRoutes.rootPath()} replace />
  }

  return loggedIn ? (
    <FirstLoading className="vh-100">
      {id ? (
        <Cable>
          <Outlet />
        </Cable>
      ) : (
        "User not found"
      )}
    </FirstLoading>
  ) : (
    <Navigate to={routes.signInV2()} state={{ from }} replace />
  )
}

PrivateRoutes.propTypes = {
  accessRole: PropTypes.string
}

export default PrivateRoutes
