import React, { useEffect } from "react"
import { Layout } from "antd"
import styles from "./PersonalInfoLayout.module.css"
import { Sidebar } from "./Sidebar"
import { SubHeader } from "./SubHeader"
import { useDispatch, useSelector } from "react-redux"
import { setMenuItemActive } from "store/pro-wizard-menu"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { routes } from "router"
import { guideSelector } from "store/user"

const { Content } = Layout

export const PersonalInfoLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useSelector(guideSelector)

  useEffect(() => {
    const menuId = location.pathname.split("/").filter(Boolean).pop()
    dispatch(setMenuItemActive(menuId))
  }, [dispatch, location.pathname])

  if (!id) {
    return <Navigate to={routes.guideRootPath()} replace />
  }

  return (
    <Layout className={styles.layout}>
      <SubHeader />
      <Layout className={styles.contentLayout}>
        <Sidebar />
        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}
