import i18n from "i18n"
import { routes } from "router/routes"

const proNewListingMenuKeys = {
  details: "details",
  scheduling: "scheduling",
  media: "media",
  resources: "resources",
  location: "location",
  preview: "preview"
}

export const proNewListingMenuList = [
  {
    label: i18n.t("listings.tabs.details.label"),
    title: i18n.t("listings.tabs.details.title"),
    path: (id) => routes.proNewListingDetailsPath({ id }),
    id: proNewListingMenuKeys.details,
    percent: 0
  },
  {
    label: i18n.t("listings.tabs.scheduling.label"),
    title: i18n.t("listings.tabs.scheduling.title"),
    path: (id) => routes.proNewListingSchedulingPath({ id }),
    id: proNewListingMenuKeys.scheduling,
    percent: 30
  },
  {
    label: i18n.t("listings.tabs.media.label"),
    title: i18n.t("listings.tabs.media.title"),
    subTitle: i18n.t("listings.tabs.media.subtitle"),
    path: (id) => routes.proNewListingMediaPath({ id }),
    id: proNewListingMenuKeys.media,
    percent: 45
  },
  {
    label: i18n.t("listings.tabs.resources.label"),
    title: i18n.t("listings.tabs.resources.title"),
    subTitle: i18n.t("listings.tabs.resources.subtitle"),
    path: (id) => routes.proNewListingResourcesPath({ id }),
    id: proNewListingMenuKeys.resources,
    percent: 65
  },
  {
    label: i18n.t("listings.tabs.location.label"),
    title: i18n.t("listings.tabs.location.title"),
    subTitle: i18n.t("listings.tabs.location.subtitle"),
    path: (id) => routes.proNewListingLocationPath({ id }),
    id: proNewListingMenuKeys.location,
    percent: 85
  },
  {
    label: i18n.t("listings.tabs.preview.label"),
    title: i18n.t("listings.tabs.preview.title"),
    subTitle: i18n.t("listings.tabs.preview.subtitle"),
    path: (id) => routes.proNewListingPreviewPath({ id }),
    id: proNewListingMenuKeys.preview,
    percent: 95
  }
]
