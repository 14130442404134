import i18n from "i18n"
import { routes } from "router/routes"

export const proMenuKeys = {
  dashboard: "dashboard",
  bookings: "bookings",
  clients: "clients",
  messages: "messages",
  marketing: "marketing",
  accounting: "accounting",
  resources: "boats",
  experiences: "experiences",
  settings: "settings",
  guidePreview: "preview",
  faqs: "faqs",
  contactSupport: "contact-support"
}

export const proMenuList = [
  {
    groupName: i18n.t("pro.sidebar.menu.title"),
    id: "menu",
    items: [
      {
        name: i18n.t("pro.sidebar.menu.dashboard"),
        path: routes.guideDashboardPath(),
        id: proMenuKeys.dashboard,
        iconName: "dashboard"
      },
      {
        name: i18n.t("pro.sidebar.menu.bookings"),
        path: routes.guideBookingsPath(),
        id: proMenuKeys.bookings,
        iconName: "booking"
      },
      {
        name: i18n.t("pro.sidebar.menu.clients"),
        path: routes.guideClientsPath(),
        id: proMenuKeys.clients,
        iconName: "user"
      },
      {
        name: i18n.t("pro.sidebar.menu.messages"),
        path: routes.guideMessagesPath(),
        id: proMenuKeys.messages,
        iconName: "messages"
      },
      {
        name: i18n.t("pro.sidebar.menu.marketing"),
        path: routes.guideMarketingPath(),
        id: proMenuKeys.marketing,
        iconName: "marketing"
      },
      {
        name: i18n.t("pro.sidebar.menu.accounting"),
        path: routes.guideAccountingPath(),
        id: proMenuKeys.accounting,
        iconName: "finances"
      },
      {
        name: i18n.t("pro.sidebar.menu.resources"),
        path: routes.guideBoatsPath(),
        id: proMenuKeys.resources,
        iconName: "boat"
      },
      {
        name: i18n.t("pro.sidebar.menu.experiences"),
        path: routes.proExperiencesPath(),
        id: proMenuKeys.experiences,
        iconName: "experiences"
      }
    ]
  },
  {
    groupName: i18n.t("pro.sidebar.account.title"),
    id: "account",
    items: [
      {
        name: i18n.t("pro.sidebar.account.settings"),
        path: routes.proSettingsRootPath(),
        id: proMenuKeys.settings,
        iconName: "settings"
      },
      {
        name: i18n.t("pro.sidebar.account.preview"),
        path: (params) => routes.publicGuidePath(params),
        id: proMenuKeys.guidePreview,
        iconName: "Play",
        iconType: "feather"
      }
    ]
  },
  {
    groupName: i18n.t("pro.sidebar.help.title"),
    id: "help",
    items: [
      {
        name: i18n.t("pro.sidebar.help.faqs"),
        path: routes.guideFaqPath(),
        id: proMenuKeys.faqs,
        iconName: "faq"
      },
      {
        name: i18n.t("pro.sidebar.help.contact_support"),
        path: routes.contactSupportPath(),
        id: proMenuKeys.contactSupport,
        iconName: "support"
      }
    ]
  }
]
