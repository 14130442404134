import { btnBorderRadius, colors, corners, spacings, fontSize, lineWidth } from "./variables"

export const baseTheme = {
  token: {
    lineWidth,
    colorText: colors.textPrimary,
    colorTextPlaceholder: colors.textTertiary,
    colorError: colors.error,
    colorErrorBorderHover: colors.error,
    colorErrorText: colors.error,
    colorBgContainerDisabled: colors.disabled,
    colorTextDisabled: colors.textSecondary,
    fontFamily: "'Montserrat', sans-serif",
    fontFamilyCode: "'Montserrat', sans-serif",
    fontSizeLG: fontSize.LG,
    fontSizeSM: fontSize.SM,
    fontSize: fontSize.MD,
    colorTextSecondary: colors.textSecondary,
    colorTextTertiary: colors.textSecondary,
    Button: {
      borderRadius: btnBorderRadius,
      borderRadiusLG: btnBorderRadius,
      defaultColor: colors.textSecondary,
      textTextColor: colors.textSecondary,
      colorError: colors.error,
      textHoverBg: colors.transparent,
      textTextActiveColor: colors.transparent,
      defaultHoverBorderColor: "rgba(0, 0, 0, .6)",
      defaultHoverColor: "rgba(0, 0, 0, .6)",
      fontWeight: 500,
      fontSizeLG: 14
    },
    Input: {
      paddingInline: spacings.S,
      borderRadius: corners.S,
      colorBorder: colors.lines,
      fontWeight: 400,
      activeShadow: "none",
      errorActiveShadow: "none",
      controlHeight: 44
    },
    Select: {
      colorBorder: colors.lines,
      controlHeight: 44
    },
    Typography: {
      fontWeightStrong: 600,
      titleMarginBottom: "none",
      fontSizeHeading1: 56,
      fontSizeHeading2: 38,
      fontSizeHeading3: 24,
      fontSizeHeading4: 18
    },
    Tag: {
      borderRadiusSM: btnBorderRadius
    },
    Switch: {
      colorTextQuaternary: colors.disabled,
      colorTextLightSolid: colors.lines,
      trackMinWidth: 50,
      trackMinWidthSM: 34
    },
    Form: {
      itemMarginBottom: 16
    },
    Divider: {
      marginLG: spacings.M
    },
    Card: {
      borderRadiusLG: corners.S,
      boxShadow: "0px 0px 11px 0px rgba(0, 0, 0, 0.09)",
      boxShadowTertiary: "0px 0px 11px 0px rgba(0, 0, 0, 0.09)",
      headerFontSize: 18
    },
    Radio: {
      dotSize: 12,
      radioSize: 24
    },
    Progress: {
      lineBorderRadius: corners.M,
      remainingColor: colors.blueSurface,
      colorText: "#fff"
    },
    Tooltip: {
      borderRadius: corners.S,
      paddingSM: 12,
      paddingXS: 12,
      colorBgSpotlight: "rgba(41, 42, 43, 0.9)"
    },
    Alert: {
      defaultPadding: spacings.S,
      colorInfoBg: colors.surface1,
      colorInfoBorder: colors.surface1,
      colorInfo: colors.textPrimary,
      borderRadiusLG: corners.M,
      colorText: colors.textPrimary
    },
    Notification: {
      paddingMD: spacings.M,
      paddingLG: spacings.M,
      paddingContentHorizontalLG: spacings.M,
      borderRadiusLG: corners.S
    },
    Tabs: {
      horizontalItemGutter: 0,
      horizontalItemPadding: `${spacings.M} ${spacings.XXL}`,
      horizontalMargin: 0,
      itemColor: colors.textSecondary,
      titleFontSize: fontSize.LG
    },
    Upload: {
      colorFillAlter: colors.surface2
    },
    Modal: {
      titleFontSize: 24
    }
  }
}
