import React from "react"
import { Modal as AntdModal } from "antd"
import styles from "./Modal.module.css"

export const Modal = ({ open, onCancel, children, footer = null, width = 1022, style, ...rest }) => {
  return (
    <AntdModal className={styles.modal} open={open} onCancel={onCancel} footer={footer} width={width} style={style} {...rest}>
      {children}
    </AntdModal>
  )
}
