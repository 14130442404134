import React, { useEffect, useMemo, useState } from "react"
import { Button, Flex } from "antd"
import { spacings } from "themes/variables"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { proWizardMenuList } from "./proWizardMenuList"
import { createPortal } from "react-dom"
import { VERIFICATION_STATUS } from "./constants"
import { guideSelector, updateGuide } from "store/user"
import { SidebarActionButtons } from "./SidebarActionButtons"
import { routes } from "router"
import { getProWizardErrorsList, getProWizardErrorsObject } from "./useCompletion"
import { setValidation } from "store/pro-wizard-menu"
import { receiveErrors } from "modules/errors/reducer"
import styles from "./ContentActionButtons.module.css"
import store from "store"

const menuItemsFlat = proWizardMenuList.flatMap((menuItem) => menuItem.items)

export const ContentActionButtons = ({ form, onFinish }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [sidebar, setSidebar] = useState(null)
  const { id } = useSelector(guideSelector)
  const { activeMenu, validation } = useSelector((state) => state.proWizardMenu)
  const currentMenuItemIndex = useMemo(() => menuItemsFlat.findIndex((menuItem) => menuItem.id === activeMenu), [activeMenu])
  const isFirstMenuItemActive = menuItemsFlat[0].id === activeMenu
  const isLastMenuItemActive = menuItemsFlat[menuItemsFlat.length - 1].id === activeMenu

  const sidebarElement = document.getElementById("proWizardSidebar")

  const setErrors = () => {
    const user = store.getState().user
    const errorsObject = getProWizardErrorsObject(user)
    dispatch(
      receiveErrors({
        data: { message: errorsObject },
        showToast: false
      })
    )
  }

  const navigateToNextPage = () => {
    let nextMenuItem
    for (let i = currentMenuItemIndex + 1; i < menuItemsFlat.length; i++) {
      if (menuItemsFlat[i].path) {
        nextMenuItem = menuItemsFlat[i]
        break
      }
    }

    if (nextMenuItem) {
      navigate(nextMenuItem.path)
      if (validation) {
        setErrors()
      }
    }
  }

  const navigateToPreviousPage = () => {
    let previousMenuItem
    for (let i = currentMenuItemIndex - 1; i >= 0; i--) {
      if (menuItemsFlat[i].path) {
        previousMenuItem = menuItemsFlat[i]
        break
      }
    }

    if (previousMenuItem) {
      navigate(previousMenuItem.path)
      if (validation) {
        setErrors()
      }
    }
  }

  const navigateToDashboardPage = (showCongrats) => {
    dispatch(setValidation(false))
    navigate(routes.guideDashboardPath({}, { ...(showCongrats && { showCongrats }) }))
  }

  const submitWizard = () => {
    dispatch(setValidation(true))
    setErrors()
    const user = store.getState().user
    const errors = getProWizardErrorsList(user)
    if (errors.length === 0) {
      dispatch(updateGuide(id, { guide: { verification_status: VERIFICATION_STATUS.PENDING, wizard_completed: true } })).then(() =>
        navigateToDashboardPage(true)
      )
    }
  }

  const saveForm = (callback) => {
    if (form) {
      if (form.isFieldsTouched()) {
        const values = form.getFieldsValue()
        onFinish(values, callback)
      } else {
        callback()
      }
    } else if (onFinish) {
      onFinish(null, callback)
    } else {
      callback()
    }
  }

  useEffect(() => {
    // Wait for the sidebar to be available
    const interval = setInterval(() => {
      const sidebarElement = document.getElementById("proWizardSidebar")
      if (sidebarElement) {
        setSidebar(sidebarElement)
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <Flex className="mt-4xl" gap={spacings.S}>
      {!isFirstMenuItemActive && (
        <Button
          className={styles.actionButton}
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => saveForm(navigateToPreviousPage)}
        >
          {t("global.previous")}
        </Button>
      )}
      {isLastMenuItemActive ? (
        <Button className={styles.actionButton} color="primary" variant="solid" size="large" onClick={() => saveForm(submitWizard)}>
          {t("global.submit")}
        </Button>
      ) : (
        <Button className={styles.actionButton} color="primary" variant="solid" size="large" onClick={() => saveForm(navigateToNextPage)}>
          {t("global.next")}
        </Button>
      )}
      {(sidebar || sidebarElement) &&
        createPortal(
          <SidebarActionButtons onSubmit={() => saveForm(submitWizard)} onSaveAndExit={() => saveForm(navigateToDashboardPage)} />,
          sidebar || sidebarElement
        )}
    </Flex>
  )
}
