import React from "react"
import styles from "./PageName.module.css"
import { Icon } from "ui/Icon"
import { useNavigate } from "react-router-dom"
import { Title } from "ui/Typography"

export const PageName = ({ title, backPath }) => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(backPath || -1)
  }
  return (
    <div className={styles.pageName}>
      <div onClick={handleOnClick} className={styles.iconWrapper}>
        <Icon width={16} height={16} type="feather" name="ChevronLeft" color="currentColor" />
      </div>
      <Title className="mb-0" level={3}>
        {title}
      </Title>
    </div>
  )
}
