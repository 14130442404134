import { Form } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { serialize } from "object-to-formdata"
import { routes } from "router"
import { CustomForm } from "ui/Form"
import { ListingActionButtons } from "layoutsV2/pro/ProNewListingLayout/ListingActionButtons"
import { AdditionalDetails } from "./AdditionalDetails"
import { ExperienceDetails } from "./ExperienceDetails"
import { ExperienceInfo } from "./ExperienceInfo"
import { GuestDetails } from "./GuestDetails"
import { PublishingSettings } from "./PublishingSettings"
import { updateTrip } from "store/trips"
import { modelSelector } from "store/selectors"

export const ListingDetails = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const trip = useSelector(modelSelector("trip"))
  const navigate = useNavigate()

  const onFinish = (values) => {
    form.validateFields().then(() => {
      console.log("Form values:", values)
      // price_per_person is set to avoid failing on backend. needs to be removed :TODO
      const formData = serialize({ trip: { ...values, price_per_person: 10 } }, { allowEmptyArrays: true })
      dispatch(updateTrip(trip.id, formData)).then(() => navigate(routes.proNewListingSchedulingPath()))
    })
  }

  return (
    <CustomForm form={form} layout="vertical" onFinish={onFinish}>
      <ExperienceInfo form={form} />
      <GuestDetails />
      <ExperienceDetails form={form} />
      <AdditionalDetails form={form} />
      <PublishingSettings form={form} />
      <ListingActionButtons form={form} onFinish={onFinish} />
    </CustomForm>
  )
}
