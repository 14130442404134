import React from "react"
import { Flex, Layout } from "antd"
import styles from "./Index.module.css"
import { Outlet } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { NavigationPath } from "../ProHomeLayout/NavigationPath"

export const ProExperiencesHomeLayout = () => {
  const { t } = useTranslation()

  return (
    <Layout className={classNames(styles.layout)}>
      <Flex className={styles.header}>
        <NavigationPath />
      </Flex>
      <Outlet />
    </Layout>
  )
}
