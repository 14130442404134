import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import { useTranslation } from "react-i18next"
import { Input, Button, Tabs } from "antd"
import { SearchOutlined, PlusOutlined } from "@ant-design/icons"
import { TabContent } from "./TabContent"
import { createTrip } from "store/trips"

import styles from "./ExperiencesHome.module.css"

export const ExperiencesHome = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const publishedCount = 0
  const draftsCount = 0
  const deactivatedCount = 0

  const createTripHandler = (event) => {
    if (event.persist) event.persist()
    dispatch(createTrip()).then(({ data: trip }) => trip.id && navigate(routes.proNewListingDetailsPath({ id: trip.id })))
  }

  const items = [
    {
      key: "1",
      label: `${t("experiences.empty_state.published")} ${publishedCount}`,
      children: <TabContent items={[]} onAddNewExperience={createTripHandler} />
    },
    {
      key: "2",
      label: `${t("experiences.empty_state.drafts")} ${draftsCount}`,
      children: <TabContent items={[]} onAddNewExperience={createTripHandler} />
    },
    {
      key: "3",
      label: `${t("experiences.empty_state.deactivated")} ${deactivatedCount}`,
      children: <TabContent items={[]} onAddNewExperience={createTripHandler} />
    }
  ]

  return (
    <div>
      <div className="justify-between mb-3xl">
        <Input className={styles.searchInput} placeholder={t("global.search")} prefix={<SearchOutlined />} />
        <Button type="primary" size="large" onClick={createTripHandler} icon={<PlusOutlined />}>
          {t("experiences.empty_state.add_new_item")}
        </Button>
      </div>
      <Tabs className={styles.tabs} defaultActiveKey="1" items={items} />
    </div>
  )
}
