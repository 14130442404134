import { useTranslation } from "react-i18next"
import { VerificationStatusCard } from "./VerificationStatusCard"
import { Paragraph, Text } from "ui/Typography"
import { Flex } from "antd"
import { spacings } from "themes/variables"
import { CircledIcon } from "ui/CircledIcon"

export const StatusRejected = () => {
  const { t } = useTranslation()
  const subTitle = t("pro.verification_status.rejectedSubtitle")
  const status = t("pro.verification_status.rejected")
  const editButtonLabel = t("pro.verification_status.edit_and_reapply")

  return (
    <>
      <VerificationStatusCard
        status={status}
        statusType="error"
        subTitle={subTitle}
        editButtonLabel={editButtonLabel}
        showSupportButton
        showEditButton
      >
        <Flex vertical gap={spacings.M}>
          <Flex vertical gap={spacings.S}>
            <Text variant="sub" size="md">
              {t("pro.verification_status.reasons")}
            </Text>
            <Flex gap={spacings.XS}>
              <CircledIcon name="AlertCircle" colorType="error" />
              <Paragraph size="md" className="mb-0">
                {t("pro.verification_status.reasonsText")}
              </Paragraph>
            </Flex>
          </Flex>
          <Flex vertical gap={spacings.S}>
            <Text variant="sub" size="md">
              {t("pro.verification_status.nextSteps")}
            </Text>
            <Flex gap={spacings.XS}>
              <CircledIcon name="ArrowRight" colorType="processing" />
              <Paragraph size="md" className="mb-0">
                {t("pro.verification_status.contactSupport")}
              </Paragraph>
            </Flex>
            <Flex gap={spacings.XS}>
              <CircledIcon name="ArrowRight" colorType="processing" />
              <Paragraph size="md" className="mb-0">
                {t("pro.verification_status.updateApplication")}
              </Paragraph>
            </Flex>
          </Flex>
        </Flex>
      </VerificationStatusCard>
    </>
  )
}
