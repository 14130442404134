import { useDevice } from "hooks/useDevice"
import { Text, Title } from "ui/Typography"

export const ContentHeader = ({ title, description }) => {
  const { isMobile } = useDevice()
  const classname = isMobile ? "ml-auto mr-auto mb-xl" : "mb-4xl"
  return (
    <div className={classname}>
      <div className={isMobile && "justify-center"}>
        {title &&
          (isMobile ? (
            <Text variant="sub" size="lg" className="mb-xl">
              {title}
            </Text>
          ) : (
            <Title level={3}>{title}</Title>
          ))}
      </div>
      {description && (
        <Text variant="body" size="md">
          {description}
        </Text>
      )}
    </div>
  )
}
