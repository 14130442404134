import { Text, Title } from "ui/Typography"
import styles from "./PageTitle.module.css"

export const PageTitle = ({ title, subTitle }) => {
  return (
    <div className={styles.wrapper}>
      {title && (
        <Title level={3} className="mb-0">
          {title}
        </Title>
      )}
      {subTitle && (
        <Text variant="sub" size="lg">
          {subTitle}
        </Text>
      )}
    </div>
  )
}
