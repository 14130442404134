import i18n from "i18n"
import { ACCEPTED_MEDIA_TYPES } from "constants/form"
export const processMediaUpload = (file) =>
  new Promise(async (resolve, reject) => {
    const fileType = file.type.split("/")[0]

    if (fileType === "image") {
      try {
        const result = await processUploadPhoto(file)
        resolve(result)
      } catch (error) {
        reject(error || new Error(i18n.t("media_upload.photo_process_failed")))
      }
    } else if (fileType === "video") {
      const supportedVideoFormats = ACCEPTED_MEDIA_TYPES.video

      if (supportedVideoFormats.includes(file.type)) {
        try {
          const result = await processUploadVideo(file)
          resolve(result)
        } catch (error) {
          reject(error || new Error(i18n.t("media_upload.video_process_failed")))
        }
      } else {
        reject(new Error(i18n.t("media_upload.video_format_unsupported")))
      }
    } else {
      reject(new Error(i18n.t("media_upload.media_file_unsupported")))
    }
  })

export const processUploadPhoto = (file) =>
  new Promise(async (resolve, reject) => {
    const mediaType = file.type.split("/").at(0)
    const file_preview = URL.createObjectURL(file)
    const numericUid = Date.now() + Math.floor(Math.random() * 1000)

    const photo = {
      uid: `local-${file.uid}`,
      file,
      file_type: "photo",
      file_preview,
      mediaType,
      id: numericUid
    }
    const attributes = {
      file,
      file_type: "photo"
    }
    if (photo && attributes) resolve({ photo, attributes })
    else reject(null)
  })

export const processUploadVideo = (file) =>
  new Promise(async (resolve, reject) => {
    const supportedFormats = ACCEPTED_MEDIA_TYPES.video

    if (!supportedFormats.includes(file.type)) {
      reject(new Error(i18n.t("media_upload.video_format_unsupported")))
      return
    }

    const mediaType = "video"
    const file_preview = URL.createObjectURL(file)

    try {
      const { blob, base64 } = await generateVideoThumbnail(file, file_preview)
      const numericUid = Date.now() + Math.floor(Math.random() * 1000)

      const video = {
        uid: `local-${file.uid}`,
        file,
        file_type: "video",
        file_preview,
        preview_url: base64,
        blob,
        mediaType,
        id: numericUid
      }

      const attributes = {
        file,
        file_type: "video",
        blob
      }

      if (video && attributes) resolve({ video, attributes })
      else reject(null)
    } catch (error) {
      reject(new Error(i18n("media_upload.video_thumbnail_error") + error.message))
    }
  })

const generateVideoThumbnail = (file, videoUrl) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video")
    video.preload = "metadata"
    video.src = videoUrl
    video.muted = true

    video.onloadeddata = () => {
      video.currentTime = 1
    }

    video.onseeked = () => {
      try {
        const canvas = document.createElement("canvas")
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        const ctx = canvas.getContext("2d")
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(
          (blob) => {
            video.pause()
            video.src = ""
            video.load()

            const base64 = URL.createObjectURL(blob)
            resolve({
              blob,
              base64
            })
          },
          "image/jpeg",
          0.8
        )
      } catch (error) {
        reject(error)
      }
    }

    video.onerror = () => {
      reject(new Error(i18n.t("media_upload.video_thumbnail_error")))
    }

    video.load()
  })
}

export const isMediaFileVideo = (media) =>
  media?.file_type === "video" ||
  media?.mediaType === "video" ||
  (media?.file?.type && media.file.type.startsWith("video/")) ||
  (media?.url || "").match(/\.(mp4|webm|ogg|mov|m4v|quicktime|qt)$/i) ||
  (media?.file?.name || "").match(/\.(mp4|webm|ogg|mov|m4v|quicktime|qt)$/i)
