import i18n from "i18n"
export const ACTIVITIES = ["fishing", "hunting"]
export const GUESTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const AVAILABLE_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
export const SKILL_LEVELS = [
  {
    value: "beginner",
    label: i18n.t("experiences.details.skill_levels.beginner")
  },
  {
    value: "intermediate",
    label: i18n.t("experiences.details.skill_levels.intermediate")
  },
  {
    value: "advanced",
    label: i18n.t("experiences.details.skill_levels.advanced")
  },
  {
    value: "professional",
    label: i18n.t("experiences.details.skill_levels.professional")
  }
]
export const TRIP_LENGTH = {
  full_day: "1 day",
  half_day: "1/2 day"
}
export const ECOSYSTEMS = {
  fishing: ["fresh_water", "brackish_water", "salt_water"],
  hunting: ["fresh_water", "brackish_water", "salt_water"]
}
export const ACTIVITY_TYPES = {
  fishing: ["lake", "river", "inshore", "nearshore", "offshore"],
  hunting: ["lake", "river", "inshore", "nearshore", "offshore"]
}
export const PRICING_TYPES = ["charge_per_person", "charge_flat_rate", "set_minimum_rate"]

export const TRIP_FILTERS_HELP_ATTRIBUTES = ["lat", "lng", "address", "zoom"]
export const TRIP_FILTERS = [
  ...TRIP_FILTERS_HELP_ATTRIBUTES,
  "activity",
  "guest_count",
  "skill_level_eq",
  "fishing_ecosystem_eq",
  "hunting_ecosystem_eq",
  "price_per_person_gteq",
  "price_per_person_lteq",
  "skill_level_sort",
  "price_per_person_sort",
  "boat_custom_info_contains",
  "boat_safety_contains"
]

export const CATEGORIES = [
  { id: 0, value: "0", name: i18n.t("experiences.details.categories.fishing") },
  { id: 1, value: 1, name: i18n.t("experiences.details.categories.boat_charters") },
  { id: 2, value: 2, name: i18n.t("experiences.details.categories.water_taxis") },
  { id: 3, value: 3, name: i18n.t("experiences.details.categories.scuba_snorkeling") },
  { id: 4, value: 4, name: i18n.t("experiences.details.categories.horseback_riding") },
  { id: 5, value: 5, name: i18n.t("experiences.details.categories.wakeboarding") },
  { id: 6, value: 6, name: i18n.t("experiences.details.categories.surfing") },
  { id: 7, value: 7, name: i18n.t("experiences.details.categories.water_rafting") },
  { id: 8, value: 8, name: i18n.t("experiences.details.categories.parasailing") },
  { id: 9, value: 9, name: i18n.t("experiences.details.categories.tours") },
  { id: 10, value: 10, name: i18n.t("experiences.details.categories.wildlife_encounters") },
  { id: 11, value: 11, name: i18n.t("experiences.details.categories.paddleboard") },
  { id: 12, value: 12, name: i18n.t("experiences.details.categories.bike_ebike") },
  { id: 13, value: 13, name: i18n.t("experiences.details.categories.scooter") },
  { id: 14, value: 14, name: i18n.t("experiences.details.categories.excursion") },
  { id: 15, value: 15, name: i18n.t("experiences.details.categories.other") }
]

export const TRIP_ITEMS = [
  { id: 0, value: "lunch", name: i18n.t("experiences.details.items.lunch") },
  { id: 1, value: "snacks", name: i18n.t("experiences.details.items.snacks") },
  { id: 2, value: "bottled_water", name: i18n.t("experiences.details.items.bottled_water") },
  { id: 3, value: "cooler", name: i18n.t("experiences.details.items.cooler") },
  { id: 4, value: "ice", name: i18n.t("experiences.details.items.ice") },
  { id: 5, value: "sunglasses", name: i18n.t("experiences.details.items.sunglasses") },
  { id: 6, value: "sunscreen", name: i18n.t("experiences.details.items.sunscreen") },
  { id: 7, value: "swimwear", name: i18n.t("experiences.details.items.swimwear") },
  { id: 8, value: "towels", name: i18n.t("experiences.details.items.towels") },
  { id: 9, value: "water_shoes", name: i18n.t("experiences.details.items.water_shoes") },
  { id: 10, value: "waterproof_bag", name: i18n.t("experiences.details.items.waterproof_bag") },
  { id: 11, value: "insect_repellent", name: i18n.t("experiences.details.items.insect_repellent") },
  { id: 12, value: "tackle", name: i18n.t("experiences.details.items.tackle") },
  { id: 13, value: "fishing_lure", name: i18n.t("experiences.details.items.fishing_lure") },
  { id: 14, value: "hooks", name: i18n.t("experiences.details.items.hooks") },
  { id: 15, value: "sinkers", name: i18n.t("experiences.details.items.sinkers") },
  { id: 16, value: "fishing_poles", name: i18n.t("experiences.details.items.fishing_poles") },
  { id: 17, value: "snorkel_mask", name: i18n.t("experiences.details.items.snorkel_mask") },
  { id: 18, value: "first_aid_kit", name: i18n.t("experiences.details.items.first_aid_kit") },
  { id: 19, value: "life_jackets", name: i18n.t("experiences.details.items.life_jackets") },
  { id: 20, value: "some_luck", name: i18n.t("experiences.details.items.some_luck") }
]

export const TRIP_LANGUAGES = [
  { id: 0, value: "english", label: i18n.t("global.languages.english") },
  { id: 0, value: "spanish", label: i18n.t("global.languages.spanish") }
]
