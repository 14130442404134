import React from "react"
import { useMatch } from "react-router-dom"
import { routes } from "router/routes"
import { useSelector } from "react-redux"
import { StatusPending } from "./StatusPending"
import { StatusInProgress } from "./StatusInProgress"
import { StatusRejected } from "./StatusRejected"
import { VERIFICATION_STATUS } from "layoutsV2/pro/PersonalInfoLayout/constants"

export default function VerificationStatusPage({ children }) {
  const { guide, role } = useSelector((state) => state.user)
  const isSettingsPage = useMatch({ path: routes.proSettingsRootPath(), end: false })
  const isContactSupportPage = useMatch({ path: routes.contactSupportPath(), end: false })
  const isUser = role === "user"
  const status = guide?.verification_status
  const isInProgress = status === VERIFICATION_STATUS.IN_PROGRESS
  const isPending = status === VERIFICATION_STATUS.PENDING
  const isApproved = status === VERIFICATION_STATUS.APPROVED
  const isRejected = status === VERIFICATION_STATUS.REJECTED

  if (isUser || isSettingsPage || isContactSupportPage || isApproved) return children
  if (isPending) return <StatusPending />
  if (isInProgress) return <StatusInProgress />
  if (isRejected) return <StatusRejected />
}
