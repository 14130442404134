import React from "react"
import { Modal } from "ui/Modal"
import { VideoPlayer } from "ui/VideoPlayer"

export const VideoPlayerModal = ({ open, selectedMedia, handleClose }) => (
  <Modal
    open={open}
    onCancel={handleClose}
    width={800}
    centered
    destroyOnClose
    footer={null}
    bodyStyle={{
      maxHeight: "80vh",
      overflow: "auto"
    }}
    style={{
      maxHeight: "90vh",
      overflow: "hidden"
    }}
  >
    {selectedMedia && (
      <VideoPlayer
        src={selectedMedia.url || selectedMedia.file_preview}
        thumbnailUrl={selectedMedia.preview_url || selectedMedia.placeholder_url}
        className="w-full p-3xl"
      />
    )}
  </Modal>
)
