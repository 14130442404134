import React from "react"
import { Form, Radio as AntdRadio } from "antd"
import { useTranslation } from "react-i18next"
import { Card } from "ui/Card"
import { Radio } from "ui/Radio"

export const AdditionalDetails = ({ form }) => {
  const [t] = useTranslation()

  const handleRadioChange = (field, value) => {
    form.setFieldsValue({ [field]: value })
  }

  return (
    <>
      <Card title={t("experiences.details.additional_details")} className="mb-3xl">
        <Form.Item name="accessibility_enabled" label={t("experiences.details.labels.is_accessible")} initialValue={false}>
          <AntdRadio.Group onChange={(e) => handleRadioChange("accessibility_enabled", e.target.value)}>
            <Radio value={true}>{t("global.yes")}</Radio>
            <Radio value={false}>{t("global.no")}</Radio>
          </AntdRadio.Group>
        </Form.Item>

        <Form.Item name="alcohol_allowed" label={t("experiences.details.labels.alcohol_allowed")} initialValue={false}>
          <AntdRadio.Group onChange={(e) => handleRadioChange("alcohol_allowed", e.target.value)}>
            <Radio value={true}>{t("global.yes")}</Radio>
            <Radio value={false}>{t("global.no")}</Radio>
          </AntdRadio.Group>
        </Form.Item>

        <Form.Item name="children_allowed" label={t("experiences.details.labels.child_friendly")} initialValue={false}>
          <AntdRadio.Group onChange={(e) => handleRadioChange("children_allowed", e.target.value)}>
            <Radio value={true}>{t("global.yes")}</Radio>
            <Radio value={false}>{t("global.no")}</Radio>
          </AntdRadio.Group>
        </Form.Item>
      </Card>
    </>
  )
}
