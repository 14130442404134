import React from "react"
import classNames from "classnames"
import { Select } from "antd"
import { Tag } from "antd"
import styles from "./MultipleSelect.module.css"

const { Option } = Select

export const MultipleSelect = ({ placeholder, options, ...rest }) => {
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag
        className={classNames(styles.tag)}
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    )
  }

  return (
    <Select
      popupClassName={styles.dropdown}
      mode="multiple"
      placeholder={placeholder}
      style={{ width: "100%" }}
      tagRender={tagRender}
      {...rest}
    >
      {options.map((option) => (
        <Option key={option.name} value={option.value}>
          {option.name}
        </Option>
      ))}
    </Select>
  )
}
