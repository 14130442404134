import { Title } from "ui/Typography"
import styles from "./ProgressRow.module.css"
import { useTranslation } from "react-i18next"
import { Button, Progress } from "antd"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import { useProgressValue } from "../PersonalInfoLayout/useCompletion"

export const ProgressRow = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const progressValue = useProgressValue()

  const onButtonClick = () => navigate(routes.proPersonalInfoRootPath())

  return (
    <div className={styles.progress}>
      <div>
        <Progress
          percent={progressValue}
          size={[133, 20]}
          percentPosition={{ align: "end", type: "inner" }}
          trailColor="#fff"
          format={(percent) => <div className={styles.progressNumber}>{percent}%</div>}
        />
      </div>
      <Title level={4} className="mb-0">
        {t("pro.verification_status.inProgressHeaderText")}
      </Title>
      <Button className="pr-l pl-l" color="primary" variant="solid" onClick={onButtonClick}>
        {t("pro.verification_status.continueSetup")}
      </Button>
    </div>
  )
}
