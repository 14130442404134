import { Form, Button, Checkbox, Card } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Text } from "ui/Typography"
import { ListingActionButtons } from "layoutsV2/pro/ProNewListingLayout/ListingActionButtons"
import { SeasonTypeSelector } from "./SeasonTypeSelector"
import { DateRangeBlock } from "./DateRangeBlock"
import { useListingScheduling } from "./utils/useListingScheduling"
import { SEASON_TYPES } from "./utils/constants"
import dayjs from 'dayjs'

export const ListingScheduling = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [form] = Form.useForm()

  const {
    trip,
    isSeasonalExperience,
    timeBlocks,
    handleSeasonChange,
    handleAddTimeBlock,
    handleRemoveTimeBlock,
    handleSaveDraftAndExit,
    handleNext,
    handlePrevious
  } = useListingScheduling(id, form)

  return (
    <>
      <Card className="mb-3xl">
        <Form form={form} layout="vertical" autoComplete="off">
          <Text variant="sub" size="lg" className="mb-l block">
            {t("listings.tabs.scheduling.sections.seasons.title")}
          </Text>
          <SeasonTypeSelector
            onChange={handleSeasonChange}
            value={isSeasonalExperience ? SEASON_TYPES.SEASONAL : SEASON_TYPES.ALL_YEAR}
          />
          {isSeasonalExperience && (
            <>
              {timeBlocks.map((blockIndex) => {
                const dateRange = trip?.available_date_ranges?.[blockIndex]
                return (
                  <DateRangeBlock
                    key={blockIndex}
                    blockIndex={blockIndex}
                    onDelete={handleRemoveTimeBlock}
                    isFirstBlock={blockIndex === 0}
                    isSeasonalActive={isSeasonalExperience}
                    initialStartDate={dateRange?.start ? dayjs(dateRange.start) : undefined}
                    initialEndDate={dateRange?.end ? dayjs(dateRange.end) : undefined}
                  />
                )
              })}
              <Button
                type="link"
                icon={<PlusOutlined />}
                onClick={handleAddTimeBlock}
                className="mt-m pl-0">
                {t("listings.tabs.scheduling.sections.seasons.add_time_block")}
              </Button>
              <Form.Item
                name="recurringYearly"
                valuePropName="checked"
                className="mt-l"
                initialValue={trip?.available_date_ranges?.length > 0 ? trip.recurring_yearly : true}>
                <Checkbox>
                  {t("listings.tabs.scheduling.sections.seasons.recurring_yearly")}
                </Checkbox>
              </Form.Item>
            </>
          )}
        </Form>
      </Card>
      <ListingActionButtons
        onSaveDraftAndExit={handleSaveDraftAndExit}
        onNext={handleNext}
        onPrevious={handlePrevious}
        form={form}
      />
    </>
  )
}
