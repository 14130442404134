import React, { useEffect, useState } from "react"
import { Layout } from "antd"
import styles from "./Index.module.css"
import { Outlet, useLocation } from "react-router-dom"
import { Sidebar } from "./Sidebar"
import { useDispatch, useSelector } from "react-redux"
import { setProHomeMenuItemActive } from "store/pro-home-menu"
import classNames from "classnames"
import { routes } from "router/routes"
import { ProgressRow } from "./ProgressRow"
import { guideSelector } from "store/user"
import { VERIFICATION_STATUS } from "../PersonalInfoLayout/constants"
import VerificationStatusPage from "pagesV2/pro/Verification/VerificationStatusPage"

const { Content } = Layout

export const ProHomeLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [menuId, setMenuId] = useState()
  const guide = useSelector(guideSelector)
  const isInProgress = guide?.verification_status === VERIFICATION_STATUS.IN_PROGRESS

  useEffect(() => {
    const menuPath = location.pathname.split(routes.proHomeRootPath()).filter(Boolean)[0]
    const menuId = menuPath?.split("/").filter(Boolean)[0]
    dispatch(setProHomeMenuItemActive(menuId))
    setMenuId(menuId)
  }, [dispatch, location.pathname])

  return (
    <div className={styles.wrapper}>
      {isInProgress && <ProgressRow />}
      <Layout className={styles.layout}>
        <Sidebar />
        <Content className={classNames(styles.content, menuId && styles.hasMenuId)}>
          <VerificationStatusPage>
            <Outlet />
          </VerificationStatusPage>
        </Content>
      </Layout>
    </div>
  )
}
