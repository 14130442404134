import { Button, Flex, Modal } from "antd"
import { useTranslation } from "react-i18next"
import { spacings } from "themes/variables"
import { Icon } from "ui/Icon"
import styles from "./ContactSupportModal.module.css"
import { Text, Title } from "ui/Typography"
import { Link } from "react-router-dom"
import { useDevice } from "hooks/useDevice"

const supportEmail = "cx@ds.guide"
const supportPhone = "+12523748433"
const supportPhoneDisplay = "1-252-374-8433"

export const ContactSupportModal = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const { isMobile } = useDevice()

  const emailBtnClickHandler = (e) => {
    window.open(`mailto:${supportEmail}`, "_blank")
    e.preventDefault()
  }
  const phoneBtnClickHandler = (e) => {
    if (isMobile) {
      window.open(`tel:${supportPhone}`, "_blank")
    } else {
      window.open(`skype:${supportPhone}?call`, "_blank")
    }
    e.preventDefault()
  }

  return (
    <Modal
      width="431px"
      open={open}
      centered
      onCancel={() => setOpen(false)}
      footer={[
        <Flex justify="center" className="pb-xxs pt-s" gap={spacings.S}>
          <Button className="pr-4xl pl-4xl" color="primary" variant="solid" size="large" onClick={emailBtnClickHandler}>
            <Icon name="Mail" color="currentColor" />
            {t("pro.contact_support.email_us")}
          </Button>
          <Button className="pr-4xl pl-4xl" color="primary" variant="solid" size="large" onClick={phoneBtnClickHandler}>
            <Icon name="Phone" color="currentColor" />
            {t("pro.contact_support.call_us")}
          </Button>
        </Flex>
      ]}
    >
      <Flex vertical align="center" gap={spacings.XL}>
        <Icon name="support" className={styles.icon} />
        <Flex vertical gap={spacings.M}>
          <Title level={3} className="m-auto">
            {t("pro.contact_support.title")}
          </Title>
          <Text variant="body" size="md">
            {t("pro.contact_support.subTitle")}
          </Text>
          <Flex vertical gap={spacings.XS}>
            <Flex gap={spacings.XS}>
              <Text variant="sub" size="md">
                {t("pro.contact_support.email_support")}
              </Text>
              <Text link>
                <Link to={`mailto:${supportEmail}`} target="_blank">
                  {supportEmail}
                </Link>
              </Text>
            </Flex>
            <Flex gap={spacings.XS}>
              <Text variant="sub" size="md">
                {t("pro.contact_support.phone_support")}
              </Text>
              <Text>{supportPhoneDisplay}</Text>
            </Flex>
          </Flex>
          <Text variant="body" size="md">
            {t("pro.contact_support.availabilityText")}
          </Text>
        </Flex>
      </Flex>
    </Modal>
  )
}
