import { colorMap } from "ui/Tag"
import { hexToRGB } from "helpers/color"
import { Icon } from "ui/Icon"
import { spacings } from "themes/variables"

export const CircledIcon = ({ colorType = "default", ...rest }) => {
  const color = colorMap[colorType]
  const [r, g, b] = hexToRGB(color)
  const styles = {
    color: color,
    background: `rgba(${r}, ${g}, ${b}, 0.1)`,
    padding: spacings.XXS,
    display: "flex",
    borderRadius: "100%",
    height: "fit-content"
  }

  return (
    <div style={styles}>
      <Icon width={spacings.M} height={spacings.M} color="currentColor" {...rest} />
    </div>
  )
}
