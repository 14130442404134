import React, { useState } from "react"
import classNames from "classnames"
import { Upload, Button, message } from "antd"
import { Text } from "ui/Typography"
import { Icon } from "ui/Icon"
import { useTranslation } from "react-i18next"
import styles from "./MediaUpload.module.css"
import { ACCEPTED_MEDIA_TYPES, MAX_MEDIA_SIZE } from "constants/form"
import { colors } from "themes/variables"
import { processMediaUpload } from "utils/media-upload"

export const MediaUpload = ({
  className = "",
  onFileListChange,
  acceptedTypes = ACCEPTED_MEDIA_TYPES.all,
  imageInfo = "",
  videoInfo = "",
  invalidFormatMessage,
  sizeLimitExceededMessage,
  maxImageSize = MAX_MEDIA_SIZE.image,
  maxVideoSize = MAX_MEDIA_SIZE.video,
  lastPosition
}) => {
  const { t } = useTranslation()
  const [fileList, setFileList] = useState([])

  const handleUpload = async (file) => {
    try {
      setFileList((prev) => prev.map((f) => (f.id === file.id ? { ...f, status: "uploading", percent: 0 } : f)))
      const { photo, video, attributes } = await processMediaUpload(file)
      const media = photo || video
      media.position = lastPosition
      attributes.position = lastPosition
      onFileListChange([media], [attributes])
      setFileList((prev) => prev.map((f) => (f.id === file.id ? { ...f, status: "done", percent: 100 } : f)))
      message.success(`${file.name} uploaded successfully!`)
    } catch (error) {
      setFileList((prev) => prev.map((f) => (f.id === file.id ? { ...f, status: "error" } : f)))
      message.error(`${file.name} upload failed.`)
    }
  }

  const beforeUpload = (file) => {
    const isValidType = acceptedTypes.includes(file.type)
    if (!isValidType) {
      message.error(invalidFormatMessage || t("media_upload.messages.invalid_format"))
      return Upload.LIST_IGNORE
    }

    const isImage = file.type.startsWith("image/")
    const isVideo = file.type.startsWith("video/")

    if (isImage && file.size / 1024 / 1024 > maxImageSize) {
      message.error(
        sizeLimitExceededMessage ||
          t("media_upload.messages.image_size_limit_exceeded", {
            maxSize: maxImageSize
          })
      )
      return Upload.LIST_IGNORE
    }

    if (isVideo && file.size / 1024 / 1024 > maxVideoSize) {
      message.error(
        sizeLimitExceededMessage ||
          t("media_upload.messages.video_size_limit_exceeded", {
            maxSize: maxVideoSize
          })
      )
      return Upload.LIST_IGNORE
    }

    setFileList((prev) => [
      ...prev,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading",
        percent: 0,
        originFileObj: file
      }
    ])

    handleUpload(file)
    return false
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  return (
    <Upload
      accept={acceptedTypes.join(",")}
      customRequest={() => {}}
      onChange={onChange}
      beforeUpload={beforeUpload}
      fileList={fileList}
      showUploadList={false}
    >
      <Button className={classNames(className, styles.upload)} variant="dashed">
        <div className="align-center justify-center">
          <div className="flex-column justify-center align-center">
            <div className={styles.circle}>
              <Icon name="Upload" width={16} height={16} strokeWidth={2.4} />
            </div>
            <Text variant="link" className="mb-xxs">
              {t("global.upload")}
            </Text>
            {imageInfo && (
              <Text className="flex-1" variant="caption" size="md" color={colors.textSecondary}>
                {imageInfo}
              </Text>
            )}
            {videoInfo && (
              <Text className="flex-1" variant="caption" size="md" color={colors.textSecondary}>
                {videoInfo}
              </Text>
            )}
          </div>
        </div>
      </Button>
    </Upload>
  )
}
