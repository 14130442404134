import { Button, Flex } from "antd"
import styles from "./ListingActionButtons.module.css"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { spacings } from "themes/variables"
import { proNewListingMenuList } from "./proNewListingMenuList"
import { useMemo } from "react"
import { ListingCancelButton } from "./ListingCancelButton"

export const ListingActionButtons = ({ form, onFinish, onNext, onSaveDraftAndExit, onPrevious }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const tabId = pathname.split("/").filter(Boolean).pop()
  const currentTabIndex = useMemo(() => proNewListingMenuList.findIndex((tabItem) => tabItem.id === tabId), [tabId])
  const isFirstTab = currentTabIndex === 0
  const isLastTab = currentTabIndex === proNewListingMenuList.length - 1

  const navigateToNextTab = () => {
    if (currentTabIndex !== -1) {
      let nextTabItem
      for (let i = currentTabIndex + 1; i < proNewListingMenuList.length; i++) {
        if (proNewListingMenuList[i].path) {
          nextTabItem = proNewListingMenuList[i]
          break
        }
      }
      if (nextTabItem) {
        navigate(nextTabItem.path)
      }
    }
  }

  const btnClickHandler = (navigateTo) => {
    if (onNext) {
      onNext()
    } else if (onFinish) {
      const values = form.getFieldsValue()
      onFinish(values, navigateTo)
    }
  }

  const publishBtnHandler = () => {}

  const saveAndExitHandler = () => {
    if (onSaveDraftAndExit) {
      onSaveDraftAndExit()
    }
  }

  return (
    <>
      <ListingCancelButton form={form} onFinish={onFinish} />
      <div className={styles.wrapper}>
        {!isFirstTab && (
          <Button
            className="pr-xl pl-xl"
            color="primary"
            variant="outlined"
            size="large"
            onClick={onPrevious}
          >
            {t("global.previous")}
          </Button>
        )}
        <Flex gap={spacings.S} className="ml-auto">
          <Button className="pr-xl pl-xl" color="primary" variant="outlined" size="large" onClick={saveAndExitHandler}>
            {t("listings.save_draft_and_exit")}
          </Button>
          {isLastTab ? (
            <Button className="pr-xl pl-xl" color="primary" variant="solid" size="large" onClick={publishBtnHandler}>
              {t("listings.save_publish")}
            </Button>
          ) : (
            <Button className="pr-xl pl-xl" color="primary" variant="solid" size="large" onClick={() => btnClickHandler(navigateToNextTab)}>
              {t("global.next")}
            </Button>
          )}
        </Flex>
      </div>
    </>
  )
}
