import { useTranslation } from "react-i18next"
import { VerificationStatusCard } from "./VerificationStatusCard"
import { Paragraph } from "ui/Typography"
import { CongratsModal } from "./CongratsModal"
import { guideSelector } from "store/user"
import { useSelector } from "react-redux"

export const StatusPending = () => {
  const { t } = useTranslation()
  const { submitted_at = "" } = useSelector(guideSelector)
  const subTitle = `${t("pro.verification_status.pendingSubtitle")} ${submitted_at && new Date(submitted_at).toLocaleString()}`
  const mainText = t("pro.verification_status.pendingText")
  const subText = t("pro.verification_status.pendingSubText")
  const status = t("pro.verification_status.pending")

  return (
    <>
      <VerificationStatusCard status={status} subTitle={subTitle} showSupportButton>
        <Paragraph size="md" className="mb-xs">
          {mainText}
        </Paragraph>
        <Paragraph size="md" className="mb-0">
          {subText}
        </Paragraph>
      </VerificationStatusCard>
      <CongratsModal />
    </>
  )
}
