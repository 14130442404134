import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { PlayIcon } from "./PlayIcon"

export const MediaPreview = ({ media, className, wrapperClassName, onClick, playIconSize = "md", lazyLoad = true }) => {
  const isVideo =
    media?.file_type === "video" ||
    media?.mediaType === "video" ||
    (media?.file?.type && media.file.type.startsWith("video/")) ||
    (media?.url || "").match(/\.(mp4|webm|ogg|mov|m4v)$/i) ||
    (media?.file?.name || "").match(/\.(mp4|webm|ogg|mov|m4v)$/i)

  const Media = () => {
    const src = isVideo ? media?.preview_url || media?.file_preview : media?.thumbnail_url || media?.url || media?.file_preview

    if (lazyLoad) {
      return (
        <LazyLoadImage
          src={src}
          alt=""
          className={classNames("object-cover w-full h-full rounded", className)}
          wrapperClassName={classNames("w-full h-full", onClick && "cursor-pointer", wrapperClassName)}
          effect="blur"
          placeholderSrc={media?.placeholder_url}
          onClick={() => onClick?.()}
        />
      )
    }

    return (
      <img
        src={src}
        alt=""
        className={classNames("object-cover w-full h-full rounded", className)}
        wrapperClassName={classNames("w-full h-full", onClick && "cursor-pointer", wrapperClassName)}
        effect="blur"
        placeholderSrc={media?.placeholder_url}
        onClick={() => onClick?.()}
        visibleByDefault
      />
    )
  }

  if (isVideo) {
    return (
      <div className="relative w-full h-full rounded overflow-hidden">
        <Media />
        <PlayIcon
          size={playIconSize}
          onClick={(e) => {
            e.stopPropagation()
            onClick?.()
          }}
        />
      </div>
    )
  }

  return <Media />
}

MediaPreview.propTypes = {
  media: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    placeholder_url: PropTypes.string,
    type: PropTypes.oneOf(["image", "video"])
  }).isRequired,
  index: PropTypes.number.isRequired,
  onImageClick: PropTypes.func,
  playIconSize: PropTypes.string
}
