import React from "react"
import { Button, Flex } from "antd"
import { spacings } from "themes/variables"
import { useTranslation } from "react-i18next"

export const SidebarActionButtons = ({ onSubmit, onSaveAndExit }) => {
  const { t } = useTranslation()

  return (
    <Flex vertical gap={spacings.S} style={{ width: "390px" }}>
      <Button color="primary" variant="solid" size="large" onClick={onSubmit}>
        {t("global.submit")}
      </Button>
      <Button color="default" variant="text" size="large" onClick={onSaveAndExit}>
        {t("pro.wizard.save_and_exit")}
      </Button>
    </Flex>
  )
}
