import { useTranslation } from "react-i18next"
import { VerificationStatusCard } from "./VerificationStatusCard"

export const StatusInProgress = () => {
  const { t } = useTranslation()
  const title = t("pro.wizard.title")
  const subTitle = t("pro.verification_status.inProgressSubtitle")
  const status = t("pro.verification_status.in_progress")
  const editButtonLabel = t("pro.verification_status.continueSetup")

  return <VerificationStatusCard status={status} title={title} subTitle={subTitle} editButtonLabel={editButtonLabel} showEditButton />
}
