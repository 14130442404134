import { Tag as AntdTag } from "antd"
import { colors } from "themes/variables"
import { hexToRGB } from "helpers/color"

export const colorMap = {
  default: colors.textSecondary,
  success: colors.success,
  error: colors.error,
  warning: colors.warning,
  processing: colors.sportsmanBrand
}

// types could be: default, success, processing, error, warning,
export const Tag = ({ children, style, bordered = false, type = "default", ...rest }) => {
  const color = colorMap[type]
  const [r, g, b] = hexToRGB(color)
  const styles = {
    ...style,
    color: color,
    background: `rgba(${r}, ${g}, ${b}, 0.1)`
  }

  return (
    <AntdTag {...rest} bordered={bordered} style={styles}>
      {children}
    </AntdTag>
  )
}
