import { useTranslation } from "react-i18next"
import styles from "./VerificationStatusCard.module.css"
import { Paragraph, Title } from "ui/Typography"
import { Tag } from "ui/Tag"
import { Button, Flex } from "antd"
import { spacings } from "themes/variables"
import { Icon } from "ui/Icon"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import { ContactSupportModal } from "ui/ContactSupportModal"
import { useState } from "react"

export const VerificationStatusCard = ({
  title,
  status,
  statusType = "processing",
  subTitle,
  showSupportButton,
  showEditButton,
  editButtonLabel,
  children
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)

  const editBtnClickHandler = () => {
    navigate(routes.proPersonalInfoRootPath())
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.cardWrapper}>
        <Flex vertical>
          <div className={classNames(styles.cardHeader, children && styles.withSeparator)}>
            <Flex vertical gap={spacings.S}>
              <Flex align="center" gap={spacings.M}>
                <Title level={3} className="mb-0">
                  {title || t("pro.verification_status.title")}
                </Title>
                <Tag type={statusType}>{status}</Tag>
              </Flex>
              {subTitle && <Paragraph size="md">{subTitle}</Paragraph>}
            </Flex>
            <div className={styles.iconWrapper}>
              <Icon name="file" height={44} width={44} color="currentColor" />
            </div>
          </div>
          {children}
        </Flex>
        <Flex gap={spacings.S}>
          {showEditButton && (
            <Button className="pr-xl pl-xl" color="primary" variant="solid" size="large" onClick={editBtnClickHandler}>
              {editButtonLabel || t("global.edit")}
            </Button>
          )}
          {showSupportButton && (
            <Button className="pr-xl pl-xl" color="primary" variant="outlined" size="large" onClick={() => setIsContactModalOpen(true)}>
              {t("pro.verification_status.contactSupport")}
            </Button>
          )}
        </Flex>
      </div>
      <ContactSupportModal open={isContactModalOpen} setOpen={setIsContactModalOpen} />
    </div>
  )
}
