import { useTranslation } from "react-i18next"
import { CustomSelect } from ".."
import { getData } from "country-list"

const countryOptions = getData()
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((country) => ({ value: country.code, label: country.name }))

export const CountrySelector = (props) => {
  const { t } = useTranslation()
  return <CustomSelect showSearch placeholder={t("pro.addresses.country")} optionFilterProp="label" options={countryOptions} {...props} />
}
