import { Button, Flex, Modal } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import congratsSVG from "assets/imagesV2/congrats.svg"
import { Paragraph, Title } from "ui/Typography"
import { spacings } from "themes/variables"

export const CongratsModal = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(searchParams.get("showCongrats") === "true")

  const buttonClickHandler = () => {
    setIsModalOpen(false)
    setSearchParams({})
    navigate(routes.guideDashboardPath())
  }

  return (
    <Modal
      width="628px"
      open={isModalOpen}
      centered
      onCancel={buttonClickHandler}
      footer={[
        <Flex justify="center" className="pb-xxs pt-xl">
          <Button className="pr-xl pl-xl" color="primary" variant="solid" size="large" onClick={buttonClickHandler}>
            {t("pro.verification_status.congratsModalButton")}
          </Button>
        </Flex>
      ]}
    >
      <Flex vertical align="center" gap={spacings.M} className="pt-5xl mt-s">
        <img src={congratsSVG} alt="" />
        <Title level={4} className="mb-0">
          {t("pro.verification_status.congratsModalTitle")}
        </Title>
        <Flex vertical gap={spacings.XS} style={{ width: "100%" }}>
          <Paragraph size="md" className="mb-0">
            {t("pro.verification_status.congratsModalSubtitle")}
          </Paragraph>
          <Paragraph size="md" className="mb-0">
            {t("pro.verification_status.congratsModalMessage")}
          </Paragraph>
          <Paragraph size="md" className="mb-0">
            {t("pro.verification_status.congratsModalActionText")}
          </Paragraph>
        </Flex>
      </Flex>
    </Modal>
  )
}
