import React from "react"
import { Switch, Alert, Button, Space, Form } from "antd"
import { Card } from "ui/Card"
import { useTranslation } from "react-i18next"
import { Icon } from "ui/Icon"

export const PublishingSettings = ({ form }) => {
  const [t] = useTranslation()

  const handleSwitchChange = (field, checked) => {
    form.setFieldsValue({
      [field]: checked
    })
  }

  return (
    <>
      <Card title={t("experiences.details.publish_settings")} className="mb-3xl">
        <Form.Item name="publish_on_guides_site" valuePropName="checked" initialValue={true} noStyle>
          <div className="mb-m">
            <Space size="middle" align="center" className="flex">
              <Switch onChange={(checked) => handleSwitchChange("publish_on_guides_site", checked)} />
              <span>{t("experiences.details.labels.publish_marketplace")}</span>
              <Icon type="feather" name="AlertCircle" width={20} height={20} />
            </Space>
          </div>
        </Form.Item>

        <Form.Item name="publish_on_platform" valuePropName="checked" initialValue={false} noStyle>
          <div className="mb-m">
            <Space size="middle" align="center" className="flex">
              <Switch onChange={(checked) => handleSwitchChange("publish_on_platform", checked)} />
              <span>{t("experiences.details.labels.publish_website")}</span>
              <Icon type="feather" name="AlertCircle" width={20} height={20} />
            </Space>
          </div>
        </Form.Item>

        <Alert
          type="info"
          showIcon
          icon={<Icon type="feather" name="AlertCircle" width={20} height={20} />}
          message={
            <span>
              {t("experiences.details.labels.publish_website_info")} -{" "}
              <Button type="link" className="p-0">
                {t("global.website_integration")}
              </Button>
              .
            </span>
          }
        />
      </Card>
    </>
  )
}
