import { useMemo, useState } from "react"
import { Col, Row } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useTheme } from "context/AntdThemeContext"
import { Card } from "ui/AdventureModeSelect/Card"
import { themes } from "constants/theme"
import { updateUser } from "store/user"
import { ContentHeader } from "layoutsV2/pro/PersonalInfoLayout/ContentHeader"
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
import { Text } from "ui/Typography"
import { getProWizardErrorsList } from "layoutsV2/pro/PersonalInfoLayout/useCompletion"
import { useDevice } from "hooks/useDevice"

export const AdventureMode = () => {
  const { t } = useTranslation()
  const { theme, switchTheme, saveThemeToStorage } = useTheme()
  const dispatch = useDispatch()
  const [mode, setMode] = useState(theme)
  const { validation } = useSelector((state) => state.proWizardMenu)
  const user = useSelector((store) => store.user)
  const errors = useMemo(() => getProWizardErrorsList(user), [user])
  const { isMobile } = useDevice()

  const onSelect = (theme) => {
    dispatch(updateUser({ adventure_mode: theme })).then(() => {
      saveThemeToStorage(theme)
      setMode(theme)
      switchTheme(theme)
    })
  }

  return (
    <Row>
      <Col lg={16} md={24} xs={24} span={16}>
        <div className="flex-column">
          <ContentHeader
            title={t("select_adventure_mode.title")}
            description={`${t("select_adventure_mode.subtitle")}. ${t("select_adventure_mode.info")}`}
          />

          <div className="justify-center">
            <Row gutter={24} className="w-full">
              <Col span={12}>
                <Card
                  type={themes.sportsman}
                  bordered={false}
                  selected={mode === themes.sportsman}
                  onSelect={() => onSelect(themes.sportsman)}
                  isRadioButton
                />
              </Col>
              <Col span={12}>
                <Card
                  type={themes.sportswoman}
                  bordered={false}
                  selected={mode === themes.sportswoman}
                  onSelect={() => onSelect(themes.sportswoman)}
                  isRadioButton
                />
              </Col>
            </Row>
          </div>
          {isMobile && (
            <Text type="danger" className="mt-xxl">
              {validation && errors.length > 0 && t("form_validation.complete_all")}
            </Text>
          )}
          <ContentActionButtons />
        </div>
      </Col>
    </Row>
  )
}
