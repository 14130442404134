import React, { useState } from "react"
import { message, Upload } from "antd"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"
import { Text } from "ui/Typography"
import { useTranslation } from "react-i18next"
import { ACCEPTED_MEDIA_TYPES } from "constants/form"
import { processMediaUpload } from "utils/media-upload"
import styles from "./DragAndDrop.module.css"

const { Dragger } = Upload

export const DragAndDrop = ({ onFileListChange }) => {
  const { t } = useTranslation()
  const [fileList, setFileList] = useState([])

  const handleUpload = async (file) => {
    try {
      setFileList((prev) => prev.map((f) => (f.uid === file.uid ? { ...f, status: "uploading", percent: 0 } : f)))
      const { photo, video, attributes } = await processMediaUpload(file)
      const media = photo || video
      media.position = 0
      attributes.position = 0
      onFileListChange([media], [attributes])

      setFileList((prev) => prev.map((f) => (f.uid === file.uid ? { ...f, status: "done", percent: 100 } : f)))

      message.success(`${file.name} uploaded successfully!`)
    } catch (error) {
      setFileList((prev) => prev.map((f) => (f.uid === file.uid ? { ...f, status: "error" } : f)))
      message.error(`${file.name} upload failed.`)
    }
  }

  const onChange = (info) => {
    const { status } = info.file

    if (status === "removed") {
      setFileList((prev) => prev.filter((f) => f.uid !== info.file.uid))
    }

    setFileList(info.fileList)
  }

  const onDrop = (e) => {
    console.log("Dropped files", e.dataTransfer.files)
  }

  const beforeUpload = (file) => {
    const isAccepted = ACCEPTED_MEDIA_TYPES.all.some((type) => file.type.match(new RegExp(type.replace("*", ".*"))))

    const isLt10MB = file.size / 1024 / 1024 <= 10

    if (!isAccepted) {
      message.error("Please upload a supported image or video file!")
      return Upload.LIST_IGNORE
    }

    if (!isLt10MB) {
      message.error("File must be smaller than 10MB!")
      return Upload.LIST_IGNORE
    }

    setFileList((prev) => [
      ...prev,
      {
        uid: file.uid,
        name: file.name,
        status: "uploading",
        percent: 0,
        originFileObj: file
      }
    ])

    handleUpload(file)
    return false
  }

  return (
    <Dragger
      name="images"
      onChange={onChange}
      onDrop={onDrop}
      beforeUpload={beforeUpload}
      fileList={fileList}
      multiple
      accept={ACCEPTED_MEDIA_TYPES.all.join(",")}
    >
      <div className="flex-column align-center justify-center">
        <div className={styles.circle}>
          <Icon name="Upload" color={colors.textSecondary} width={24} height={24} strokeWidth={1.4} />
        </div>
        <div className="align-center justify-center">
          <div className="flex-column">
            <Text variant="link" className="mb-xxs">
              {t("global.upload")}
            </Text>
            <Text className="flex-1" variant="caption" size="lg">
              {t("pro.personal_info.profile_images.upload_info")}
            </Text>
            <Text className="flex-1" variant="caption">
              {t("pro.personal_info.profile_images.upload_restriction_info")}
            </Text>
          </div>
        </div>
      </div>
    </Dragger>
  )
}
