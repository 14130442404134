import { Button } from "antd"
import styles from "./ListingCancel.module.css"
import { ConfirmModal } from "ui/ConfirmModal"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "router/routes"

export const ListingCancelButton = ({ form, onFinish }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isConfirmOpened, setIsConfirmOpened] = useState(false)

  const navigateToMainListingsPage = () => {
    navigate(routes.proListingsRootPath())
  }

  const handleMainButtonClick = () => {
    if (form?.isFieldsTouched()) {
      setIsConfirmOpened(true)
    } else {
      navigateToMainListingsPage()
    }
  }

  const onConfirm = () => {
    const values = form.getFieldsValue()
    onFinish?.(values, navigateToMainListingsPage)
    setIsConfirmOpened(false)
  }

  const onCancel = () => {
    setIsConfirmOpened(false)
  }

  const onExit = () => {
    setIsConfirmOpened(false)
    navigateToMainListingsPage()
  }

  return (
    <>
      <Button onClick={handleMainButtonClick} className={styles.cancelWrapper} color="default" variant="text">
        {t("global.cancel")}
      </Button>
      <ConfirmModal
        open={isConfirmOpened}
        title={t("listings.confirm_modal.title")}
        info={t("listings.confirm_modal.info")}
        okLabel={t("listings.confirm_modal.ok")}
        exitLabel={t("listings.confirm_modal.cancel")}
        onOk={onConfirm}
        onCancel={onCancel}
        onExit={onExit}
        hideCancelButton
      />
    </>
  )
}
