import { combineReducers } from "redux"
import postsReducer from "./posts"
import authReducer from "./auth"
import userReducer from "./user"
import guidesReducer from "./guides"
import tripsReducer from "./trips"
import favouriteTripsReducer from "./favourite-trips"
import accountingReducer from "./accounting"
import conversationsReducer from "./conversations"
import passwordReducer from "./password"
import lightboxReducer from "./lightbox"
import windowReducer from "../modules/window/reducer"
import weatherReducer from "./weather"
import bookingsReducer from "./bookings"
import reviewsReducer from "./reviews"
import boatsReducer from "./boats"
import clientsReducer from "./clients"
import dashboardReducer from "./dashboard"
import unavailableDaysReducer from "./unavailable-days"
import templatesReducer from "./templates"
import contactUsReducer from "./contact-us"
import textGenerateReducer from "./text-generate"
import proWizardMenuReducer from "./pro-wizard-menu"
import proHomeMenuReducer from "./pro-home-menu"

import errorsReducer from "modules/errors/reducer"
import modalsReducer from "modules/modals/reducer"
import loaderWatchersReducer from "modules/loader-watchers/reducer"

const rootReducer = combineReducers({
  auth: authReducer,
  password: passwordReducer,
  user: userReducer,
  guides: guidesReducer,
  reviews: reviewsReducer,
  dashboard: dashboardReducer,
  unavailableDays: unavailableDaysReducer,
  bookings: bookingsReducer,
  clients: clientsReducer,
  boats: boatsReducer,
  trips: tripsReducer,
  favouriteTrips: favouriteTripsReducer,
  conversations: conversationsReducer,
  accounting: accountingReducer,
  posts: postsReducer,
  templates: templatesReducer,
  weather: weatherReducer,
  contactUs: contactUsReducer,
  modals: modalsReducer,
  errors: errorsReducer,
  lightbox: lightboxReducer,
  window: windowReducer,
  loaderWatchers: loaderWatchersReducer,
  textGenerate: textGenerateReducer,
  proWizardMenu: proWizardMenuReducer,
  proHomeMenu: proHomeMenuReducer
})

export default rootReducer
