import i18n from "i18n"
import { routes } from "router/routes"

export const proWizardMenuKeys = {
  profileInformation: "profile-information",
  profileImages: "profile-images",
  addresses: "addresses",
  licenses: "licenses-and-certifications",
  stripe: "connect-with-stripe",
  adventureMode: "adventure-mode"
}

export const proWizardMenuList = [
  {
    groupName: i18n.t("pro.wizard.sidebar.profile.title"),
    id: "profile",
    items: [
      {
        name: i18n.t("pro.wizard.sidebar.profile.information"),
        path: routes.proPersonalInfoProfileInfo(),
        id: proWizardMenuKeys.profileInformation,
        progressWeight: 20
      },
      {
        name: i18n.t("pro.wizard.sidebar.profile.images"),
        path: routes.proPersonalInfoProfileImages(),
        id: proWizardMenuKeys.profileImages,
        progressWeight: 20
      },
      {
        name: i18n.t("pro.wizard.sidebar.profile.address"),
        path: routes.proPersonalInfoAddressesPath(),
        id: proWizardMenuKeys.addresses,
        progressWeight: 20
      }
    ]
  },
  {
    groupName: i18n.t("pro.wizard.sidebar.pro_details.title"),
    id: "professional-details",
    items: [
      {
        name: i18n.t("pro.wizard.sidebar.pro_details.licenses"),
        path: routes.proPersonalInfoLicenses(),
        id: proWizardMenuKeys.licenses,
        progressWeight: 20
      }
    ]
  },
  {
    groupName: i18n.t("pro.wizard.sidebar.payment.title"),
    id: "payment_setup",
    items: [
      {
        path: routes.proPersonalInfoStripe(),
        name: i18n.t("pro.wizard.sidebar.payment.stripe"),
        id: proWizardMenuKeys.stripe,
        progressWeight: 20
      }
    ]
  },
  {
    groupName: i18n.t("pro.wizard.sidebar.settings.title"),
    id: "settings_and_preferences",
    items: [
      {
        path: routes.proPersonalInfAdventureMode(),
        id: proWizardMenuKeys.adventureMode,
        name: i18n.t("pro.wizard.sidebar.settings.mode"),
        progressWeight: 0
      }
    ]
  }
]
