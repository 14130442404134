import { Progress } from "antd"
import styles from "./progressBar.module.css"

export const ProgressBar = ({ percent, height = 38 }) => {
  return (
    <Progress
      percent={percent}
      format={(percent) => {
        return <div className={styles.progressNumber}>{percent}%</div>
      }}
      size={["", height]}
      percentPosition={{ align: "end", type: "inner" }}
    />
  )
}
