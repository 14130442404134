export const DATE_FORMAT = "MM/DD/YYYY"

export const SEASON_TYPES = {
  ALL_YEAR: "all-year",
  SEASONAL: "seasonal"
}

export const DATE_PICKER_PROPS = {
  autoComplete: "new-password",
  autoFill: "off",
  "data-form-type": "date"
} 
