import { Col, Flex, Row } from "antd"
import { useDevice } from "hooks/useDevice"
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
import { ContentHeader } from "layoutsV2/pro/PersonalInfoLayout/ContentHeader"
import { errorFieldsSelector } from "modules/errors/selectors"
import { StripeAccountCard } from "pagesV2/pro/Settings/StripeAccount/StripeAccountCard"
import { StripeActionButtons } from "pagesV2/pro/Settings/StripeAccount/StripeActionButtons"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { CustomInputError } from "ui/CustomInputError"

export const PersonalInfoStripe = () => {
  const { t } = useTranslation()
  const { isMobile } = useDevice()
  const errors = useSelector(errorFieldsSelector())

  return (
    <>
      <Row>
        <Col lg={16} md={24} xs={24}>
          <Flex vertical>
            <ContentHeader title={t("pro.personal_info.stripe.title")} description={t("pro.personal_info.stripe.description")} />
            <StripeAccountCard flow_type="wizard" className="mb-4xl" />
            {isMobile && (
              <Flex justify="center" className="mb-4xl">
                <StripeActionButtons flow_type="wizard" />
              </Flex>
            )}
            {errors.stripe && <CustomInputError errorKey={errors.stripe[0]} />}
          </Flex>
          <ContentActionButtons />
        </Col>
      </Row>
    </>
  )
}
