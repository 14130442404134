import React from "react"
import { useDispatch } from "react-redux"
import { Form, Button } from "antd"
import { CustomForm } from "ui/Form"
import { Modal } from "ui/Modal"
import { useTranslation } from "react-i18next"
import { Text } from "ui/Typography"
import { TextArea } from "ui/Input"
import { suggestCategory } from "store/trips"
import { notification } from "ui/Notification"
import { serialize } from "object-to-formdata"

export const AddCategoryModal = ({ isVisible, onCancel, handleClose }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(suggestCategory(serialize({ message: values.message }))).then(() => {
          form.resetFields()
          handleClose()
          notification.success({ message: t("experiences.details.messages.category_request") })
        })
      })
      .catch((info) => {
        console.log("Validate Failed:", info)
      })
  }

  return (
    <Modal
      title={t("experiences.details.request_category")}
      open={isVisible}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      footer={[
        <Button key="cancel" onClick={onCancel} size="large">
          {t("global.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} size="large">
          {t("global.send_request")}
        </Button>
      ]}
    >
      <CustomForm form={form} layout="vertical">
        <Text className="mt-3xl mb-3xl block" variant="body">
          {t("experiences.details.category_modal.info")}
        </Text>
        <Form.Item
          name="message"
          label={t("experiences.details.category_modal.option_suggestion")}
          rules={[{ required: true, message: t("experiences.details.category_modal.suggestion_error") }]}
        >
          <TextArea placeholder={t("experiences.details.category_modal.option_suggestion_placeholder")} rows={4} autoFocus />
        </Form.Item>
      </CustomForm>
    </Modal>
  )
}
