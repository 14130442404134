import { Typography } from "antd"

const variations = {
  sub: {
    fs: {
      md: 14,
      lg: 16
    },
    fw: 500
  },
  body: {
    fs: {
      md: 14,
      lg: 16
    }
  },
  caption: {
    fs: {
      md: 12,
      lg: 14
    }
  },
  link: {
    fs: {
      md: 14,
      lg: 16
    }
  }
}

export const Text = ({ children, variant = "body", size = "md", fw = "", style, color, ...rest }) => {
  const variation = variations[variant]
  let styles = {
    fontSize: variation.fs[size],
    ...((fw || variation?.fw) && { fontWeight: fw ? fw : variation?.fw && variation.fw }),
    ...style,
    color
  }

  if (variant === "link") {
    return (
      <Typography.Link target="_blank" style={styles} {...rest}>
        {children}
      </Typography.Link>
    )
  }

  return (
    <Typography.Text style={styles} {...rest}>
      {children}
    </Typography.Text>
  )
}
