import React from "react"

export const File = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.6654 3.66663H10.9987C10.0262 3.66663 9.09361 4.05293 8.40597 4.74057C7.71834 5.4282 7.33203 6.36083 7.33203 7.33329V36.6666C7.33203 37.6391 7.71834 38.5717 8.40597 39.2593C9.09361 39.947 10.0262 40.3333 10.9987 40.3333H32.9987C33.9712 40.3333 34.9038 39.947 35.5914 39.2593C36.2791 38.5717 36.6654 37.6391 36.6654 36.6666V14.6666L25.6654 3.66663Z"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M25.668 3.66663V14.6666H36.668" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M29.3346 23.8334H14.668" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M29.3346 31.1666H14.668" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.3346 16.5H16.5013H14.668" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)
