import { ListingActionButtons } from "layoutsV2/pro/ProNewListingLayout/ListingActionButtons"

export const ListingMedia = () => {
  return (
    <>
      <div>"Listing Media Page"</div>
      <ListingActionButtons />
    </>
  )
}
