import React from "react"
import PropTypes from "prop-types"
import { MediaPreview } from "ui/MediaPreview"
import { Row, Col } from "antd"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"
import styles from "./MediaGrid.module.css"

const renderMedia = (media, index, onImageClick, withActions, onDelete) => {
  return (
    <div className="relative w-full h-full">
      <MediaPreview media={media} onClick={() => onImageClick?.(index)} />
      {withActions && (
        <div
          className={styles.actionWrapper}
          onClick={(e) => {
            e.stopPropagation()
            onDelete?.(index)
          }}
        >
          <Icon type="feather" name="X" width={16} height={16} strokeWidth={2} color={colors.textSecondary} />
        </div>
      )}
    </div>
  )
}

const MediaGrid = ({ mediaItems = [], onImageClick, withActions = false, onDelete }) => {
  if (mediaItems.length === 0) return null

  const gutter = [12, 8]

  if (mediaItems.length < 5) {
    const spanLength = mediaItems.length > 1 ? 24 / mediaItems.length : 12
    return (
      <Row gutter={gutter}>
        {mediaItems.map((media, index) => (
          <Col key={index} span={spanLength}>
            {renderMedia(media, index, onImageClick, withActions, onDelete)}
          </Col>
        ))}
      </Row>
    )
  }

  const renderGrid = (startIndex) => (
    <>
      <Col className={styles.heroImage} span={12}>
        {renderMedia(mediaItems[startIndex], startIndex, onImageClick, withActions, onDelete)}
      </Col>
      {mediaItems[startIndex + 1] && (
        <Col span={12}>
          <Row gutter={gutter}>
            {Array.from({ length: 4 }).map(
              (_, i) =>
                mediaItems[startIndex + i + 1] && (
                  <Col key={i} span={12} className={styles.thumbnailImage}>
                    {renderMedia(mediaItems[startIndex + i + 1], startIndex + i + 1, onImageClick, withActions, onDelete)}
                  </Col>
                )
            )}
          </Row>
        </Col>
      )}
      {mediaItems[startIndex + 5] && (
        <Col className={styles.heroImage} span={12}>
          {renderMedia(mediaItems[startIndex + 5], startIndex + 5, onImageClick, withActions, onDelete)}
        </Col>
      )}
      {mediaItems[startIndex + 6] && (
        <Col className={styles.heroImage} span={12}>
          {renderMedia(mediaItems[startIndex + 6], startIndex + 6, onImageClick, withActions, onDelete)}
        </Col>
      )}
      {Array.from({ length: 4 }).map(
        (_, i) =>
          mediaItems[startIndex + 7 + i] && (
            <Col key={i} span={6} className={styles.thumbnailImage}>
              {renderMedia(mediaItems[startIndex + 7 + i], startIndex + 7 + i, onImageClick, withActions, onDelete)}
            </Col>
          )
      )}
    </>
  )

  return (
    <Row gutter={gutter}>
      {Array.from({ length: Math.ceil(mediaItems.length / 12) }).map((_, i) => (
        <React.Fragment key={i}>{renderGrid(i * 12)}</React.Fragment>
      ))}
    </Row>
  )
}

MediaGrid.propTypes = {
  mediaItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      placeholder_url: PropTypes.string
    })
  ).isRequired,
  onImageClick: PropTypes.func,
  withActions: PropTypes.bool,
  onDelete: PropTypes.func
}

export { MediaGrid }
