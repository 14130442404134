import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"
import { createdFavouriteTrips, deletedFavouriteTrips } from "store/favourite-trips"
import { api } from "./helpers/api"
import { isEmptyFields, toSearchParams } from "helpers/form"
import { find, remove, isEqual, reject } from "lodash"
import { formatPaginationParams } from "helpers/pagination"
import { collectionSelector, modelSelector } from "./selectors"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"
import { PAGINATION_INITIAL_STATE } from "./constants"

const EMPTY_DRAFT_FIELDS = [
  "title",
  "description",
  "start_date",
  "end_date",
  "max_guests",
  "min_guests",
  "photos",
  "skill_level",
  "bring_items",
  "included_items",
  "target_fish_species",
  "fishing_activity_type",
  "fishing_ecosystem"
]

// ACTIONS
const requested = createAction("trips/requested")
const received = createAction("trips/received")
const failed = createAction("trips/failed")
const cleaned = createAction("trips/cleaned")

const requestedTrip = createAction("trips/requestedTrip")
export const receivedTrip = createAction("trips/receivedTrip")
const failedTrip = createAction("trips/failedTrip")
const createdTrip = createAction("trips/createdTrip")
const updatedTrip = createAction("trips/updatedTrip")
const deletedTrip = createAction("trips/deletedTrip")
const cleanedTrip = createAction("trips/cleanedTrip")

const requestedFishSpecies = createAction("trips/requestedFishSpecies")
const receivedFishSpecies = createAction("trips/receivedFishSpecies")
const failedFishSpecies = createAction("trips/failedFishSpecies")

const requestedSuggestCategory = createAction("trips/requestedSuggestCategory")
const receivedSuggestCategory = createAction("trips/receivedSuggestCategory")
const failedSuggestCategory = createAction("trips/failedSuggestCategory")

const requestedFilterOptions = createAction("trips/requestedFilterOptions")
const receivedFilterOptions = createAction("trips/receivedFilterOptions")
const failedFilterOptions = createAction("trips/failedFilterOptions")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  fish_species: {
    ...LOADING_INITIAL_STATE,
    items: []
  },
  suggested_categories: {
    ...LOADING_INITIAL_STATE,
    items: []
  },
  filter_options: {
    ...LOADING_INITIAL_STATE,
    lists: {}
  },
  metadata: {
    ...PAGINATION_INITIAL_STATE
  },
  trip: {
    ...LOADING_INITIAL_STATE,
    id: null,
    title: null,
    description: null,
    status: "",
    fishing_activity_type: null,
    trip_length: "",
    min_guests: null,
    max_guests: null,
    seasonal: false,
    start_date: null,
    end_date: null,
    recurring_yearly: false,
    available_date_ranges: [],
    pricing_type: null,
    price_per_person: 0,
    flat_rate: 0,
    minimum_rate: 0,
    minimum_rate_person_count: 0,
    rate_per_additional_person: 0,
    latitude: null,
    longitude: null,
    available_days: {
      friday: true,
      monday: true,
      saturday: true,
      sunday: true,
      thursday: true,
      tuesday: true,
      wednesday: true
    },
    bring_items: [],
    included_items: [],
    target_fish_species: [],
    photos: [],
    skill_level: null,
    alcohol_allowed: false,
    accessibility_enabled: false,
    fishing_ecosystem: null,
    wizard_completed: null,
    wizard_step: "",
    existing_draft_booking: null
  }
}

// HELPERS
const isEmptyDraft = (item) => isEmptyFields(item, EMPTY_DRAFT_FIELDS) && isEqual(item.available_days, initialState.trip.available_days)

const tripsReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.props.reset ? action.payload.data : state.items.concat(action.payload.data)
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedTrip.type]: (state) => {
    setRequested(state.trip)
  },
  [receivedTrip.type]: (state, action) => {
    setReceived(state.trip)
    Object.assign(state.trip, action.payload.data || action.payload)
    if (action.payload.metadata) Object.assign(state.metadata, action.payload.metadata)
  },
  [createdTrip.type]: (state, action) => {
    setReceived(state.trip)
    const trip = action.payload.data || action.payload
    state.items = [trip, ...state.items]
    Object.assign(state.trip, trip)
  },
  [updatedTrip.type]: (state, action) => {
    setReceived(state.trip)
    const trip = find(state.items, ["id", action.payload.data.id || action.payload.id]) || {}
    Object.assign(trip, action.payload.data || action.payload)
    Object.assign(state.trip, action.payload.data || action.payload)
    state.statusUpdated = !state.statusUpdated
  },
  [deletedTrip.type]: (state, action) => {
    setReceived(state.trip)
    remove(state.items, ["id", action.payload.id])
  },
  [failedTrip.type]: (state) => {
    setFailed(state.trip)
  },
  [cleanedTrip.type]: (state) => {
    state.trip = initialState.trip
  },
  [requestedFishSpecies.type]: (state) => {
    setRequested(state.fish_species)
  },
  [receivedFishSpecies.type]: (state, action) => {
    setReceived(state.fish_species)
    state.fish_species.items = action.payload.data
  },
  [failedFishSpecies.type]: (state) => {
    setFailed(state.fish_species)
  },
  [requestedSuggestCategory.type]: (state) => {
    setRequested(state.fish_species)
  },
  [receivedSuggestCategory.type]: (state, action) => {
    setReceived(state.fish_species)
    state.fish_species.items = action.payload.data
  },
  [failedSuggestCategory.type]: (state) => {
    setFailed(state.fish_species)
  },
  [requestedFilterOptions.type]: (state) => {
    setRequested(state.filter_options)
  },
  [receivedFilterOptions.type]: (state, action) => {
    setReceived(state.filter_options)
    state.filter_options.lists = action.payload
  },
  [failedFilterOptions.type]: (state) => {
    setFailed(state.filter_options)
  },
  [createdFavouriteTrips.type]: (state, action) => {
    const trip = find(state.items, ["id", action.payload.favoritable_id])
    if (trip) trip.favorite_id = action.payload.id
    if (state.trip.id) state.trip.favorite_id = action.payload.id
  },
  [deletedFavouriteTrips.type]: (state, action) => {
    const trip = find(state.items, ["favorite_id", action.payload.id])
    if (trip) trip.favorite_id = null
    if (state.trip.id) state.trip.favorite_id = null
  }
})
export default tripsReducer

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = toSearchParams(formatPaginationParams(state.trips.metadata, nextPage))
  const serializedParams = toSearchParams(params, null, paginationParams)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: serializedParams,
      props: { reset: !nextPage }
    })
  )
}

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const trip = state.trips.trip.id === +id ? state.trips.trip : find(state.trips.items, ["id", +id])

  if (trip) {
    dispatch(receivedTrip(trip))
    return new Promise((resolve) => resolve(trip))
  }
  return dispatch(
    api({
      url,
      onStart: requestedTrip.type,
      onSuccess: receivedTrip.type,
      onError: failedTrip.type,
      params: { include_unavailable_dates: true }
    })
  )
}

//PUBLIC ACTIONS
export const getTrips = (nextPage, params) => (dispatch, getState) => getItems("/trips", nextPage, params, dispatch, getState)
export const getGuideTrips = (nextPage, params) => (dispatch, getState) => getItems("/guide/trips", nextPage, params, dispatch, getState)
export const getClientGuideTrips = (id, nextPage, params) => (dispatch, getState) =>
  getItems(`/client/guides/${id}/trips`, nextPage, params, dispatch, getState)

export const getTrip = (id) => (dispatch, getState) => getItem(`/trips/${id}`, id, dispatch, getState)
export const getGuideTrip = (id) => (dispatch, getState) => getItem(`/guide/trips/${id}`, id, dispatch, getState)

export const createTrip = () => (dispatch, getState) => {
  const state = getState()
  const { items } = state.trips
  const emptyDraftTrip = items.find(isEmptyDraft)

  if (emptyDraftTrip) {
    dispatch(receivedTrip(emptyDraftTrip))
    return new Promise((resolve) => resolve({ data: emptyDraftTrip }))
  }

  return dispatch(
    api({
      url: "/guide/trips",
      method: "post",
      onStart: requestedTrip.type,
      onSuccess: createdTrip.type,
      onError: failedTrip.type
    })
  )
}

export const updateTrip = (id, data) =>
  api({
    url: `/guide/trips/${id}`,
    method: "put",
    data: data,
    onStart: requestedTrip.type,
    onSuccess: updatedTrip.type,
    onError: failedTrip.type
  })

// Currently not in use
export const deleteTrip = (id) =>
  api({
    url: `/guide/trips/${id}`,
    method: "delete",
    onStart: requestedTrip.type,
    onSuccess: deletedTrip.type,
    onError: failedTrip.type
  })

export const duplicateTrip = (id) =>
  api({
    url: `/guide/trips/${id}/duplicate`,
    method: "post",
    onStart: requestedTrip.type,
    onSuccess: createdTrip.type,
    onError: failedTrip.type
  })

export const suggestCategory = (data) =>
  api({
    url: "/guide/trips/suggest_category",
    method: "post",
    data,
    onStart: requestedSuggestCategory.type,
    onSuccess: receivedSuggestCategory.type,
    onError: failedSuggestCategory.type
  })

export const getFishSpecies = () =>
  api({
    url: `/guide/trips/fish_species`,
    onStart: requestedFishSpecies.type,
    onSuccess: receivedFishSpecies.type,
    onError: failedFishSpecies.type
  })

export const getFilterOptions = () =>
  api({
    url: `/trips/filter_options`,
    onStart: requestedFilterOptions.type,
    onSuccess: receivedFilterOptions.type,
    onError: failedFilterOptions.type
  })

export { cleaned as cleanTrips, cleanedTrip as cleanTrip }

// SELECTORS

export const tripsWithoutEmptySelector = createSelector(collectionSelector("trips"), (trips) => reject(trips, isEmptyDraft))

export const tripLatLngSelector = createSelector(
  modelSelector("trip"),
  (trip) => trip && trip.latitude && trip.longitude && { lat: trip.latitude, lng: trip.longitude }
)

export const tripFilterOptionsSelector = (listName) =>
  createSelector(
    (state) => state.trips.filter_options.lists || {},
    (lists) => lists[listName] || []
  )
