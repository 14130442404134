import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Icon } from "ui/Icon"
import styles from "./MediaPreview.module.css"

export const PlayIcon = ({ className, onClick, size = "md" }) => {
  return (
    <div
      className={classNames(styles.playIcon, styles[size], className)}
      onClick={(e) => onClick?.(e)}
      onPointerDown={(e) => {
        e.stopPropagation()
      }}
    >
      <Icon name="play" color="#ffffff" width={size === "sm" ? 10 : undefined} height={size === "sm" ? 11 : undefined} />
    </div>
  )
}

PlayIcon.propTypes = {
  classNames: PropTypes.string,
  onClick: PropTypes.func
}
