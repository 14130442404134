import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { modelSelector } from "store/selectors"
import { getGuideTrip, updateTrip } from "store/trips"
import { SEASON_TYPES } from './constants'

export const useListingScheduling = (id, form) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const trip = useSelector(modelSelector("trip"))
  const [isSeasonalExperience, setIsSeasonalExperience] = useState(false)
  const [timeBlocks, setTimeBlocks] = useState([0])

  useEffect(() => {
    if (id) {
      dispatch(getGuideTrip(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if ('seasonal' in trip) {
      setIsSeasonalExperience(trip.seasonal)
      if (trip.available_date_ranges?.length > 0) {
        setTimeBlocks(Array.from({ length: trip.available_date_ranges.length }, (_, i) => i))
      }
    }
  }, [trip, trip?.seasonal, trip?.available_date_ranges])

  const handleSeasonChange = (e) => {
    setIsSeasonalExperience(e.target.value === SEASON_TYPES.SEASONAL)
  }

  const handleAddTimeBlock = () => {
    setTimeBlocks(prevBlocks => [...prevBlocks, prevBlocks.length])
  }

  const handleRemoveTimeBlock = (index) => {
    setTimeBlocks(prevBlocks => prevBlocks.filter(blockIndex => blockIndex !== index))
  }

  const prepareUpdateData = async (validateForm = false) => {
    const values = validateForm 
      ? await form.validateFields()
      : form.getFieldsValue()
    
    const updateData = {}

    if (!isSeasonalExperience && trip?.seasonal) {
      updateData.seasonal = false
    } else if (isSeasonalExperience) {
      updateData.seasonal = true
      updateData.recurring_yearly = values.recurringYearly

      const dateRanges = timeBlocks
        .map((_, index) => {
          const startDate = values[`seasonStart_${index}`]
          const endDate = values[`seasonEnd_${index}`]
          if (startDate && endDate) {
            return {
              start: startDate.format('YYYY-MM-DD'),
              end: endDate.format('YYYY-MM-DD')
            }
          }
          return null
        })
        .filter(Boolean)

      if (dateRanges.length > 0) {
        updateData.available_date_ranges = dateRanges
      }
    }

    return updateData
  }

  const handleSaveDraftAndExit = async () => {
    try {
      const updateData = await prepareUpdateData(false)
      
      if (Object.keys(updateData).length > 0) {
        await dispatch(updateTrip(id, { trip: updateData }))
      }

      navigate('/pro/home/experiences')
    } catch (error) {
      console.error('Error saving draft:', error)
    }
  }

  const handleNext = async () => {
    try {
      const updateData = await prepareUpdateData(true)
      
      if (Object.keys(updateData).length > 0) {
        await dispatch(updateTrip(id, { trip: updateData }))
      }

      navigate(`/pro/home/experiences/${id}/wizard/media`)
    } catch (error) {
      console.error('Form validation failed:', error)
    }
  }

  const handlePrevious = () => {
    navigate(`/pro/home/experiences/${id}/wizard/details`)
  }

  return {
    trip,
    isSeasonalExperience,
    timeBlocks,
    handleSeasonChange,
    handleAddTimeBlock,
    handleRemoveTimeBlock,
    handleSaveDraftAndExit,
    handleNext,
    handlePrevious
  }
} 
